import React, { useState } from "react";
import addProduct from "./AddProducts";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import app from "../../../../firebase";
import ImageUpload from "./ImageUploads";

import AdminLayout from "../../AdminLayout";

const storage = getStorage(app);

const ProductForm = () => {
  const [productDetails, setProductDetails] = useState({
    name: "",
    price: 0,
    description: "",
    color: "",
    brand: "",
    amount: 0,
    category: "",
    type: "",
    images: [],
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "images") {
      const newImages = Array.from(files);
      setProductDetails({ ...productDetails, images: newImages });

      const newPreviews = newImages.map((file) =>
        URL.createObjectURL(file)
      );
      setImagePreviews(newPreviews);
    } else {
      setProductDetails({ ...productDetails, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const uploadedImages = await Promise.all(
        productDetails.images.map((image) =>
          uploadImageToStorage(image, productDetails.name)
        )
      );

      const productWithImages = {
        ...productDetails,
        images: uploadedImages,
      };

      await addProduct(productWithImages);

      setShowSuccessModal(true);

      setTimeout(() => {
        setShowSuccessModal(false);
        setProductDetails({
          name: "",
          price: 0,
          description: "",
          color: "",
          brand: "",
          amount: 0,
          category: "",
          type: "",
          images: [],
        });
        setImagePreviews([]);
      }, 3000);
    } catch (error) {
      console.error("Error adding product: ", error);
    }
  };

  const handleImageChange = (newImages) => {
    setProductDetails({ ...productDetails, images: newImages });

    const newPreviews = newImages.map((file) => URL.createObjectURL(file));
    setImagePreviews(newPreviews);
  };


  const uploadImageToStorage = async (file, productName) => {
    const imageName = `${productName.replace(/\s/g, "")}_${productName.length}_${file.name}`;
    const newStorageRef = storageRef(storage, "images/" + imageName);
    await uploadBytes(newStorageRef, file);
    const downloadURL = await getDownloadURL(newStorageRef);
    return downloadURL;
  };

  return (
    <AdminLayout>
      <div className="max-w-5xl mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Add Product</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-6">
          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={productDetails.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
              Price
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={productDetails.price}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
              Quantity
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={productDetails.amount}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={productDetails.description}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="color">
              Color
            </label>
            <input
              type="text"
              id="color"
              name="color"
              value={productDetails.color}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="brand">
              Brand
            </label>
            <input
              type="text"
              id="brand"
              name="brand"
              value={productDetails.brand}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
              Category
            </label>
            <input
              type="text"
              id="category"
              name="category"
              value={productDetails.category}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3 md:col-span-1">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
              Type
            </label>
            <input
              type="text"
              id="type"
              name="type"
              value={productDetails.type}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4 col-span-3">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="images">
              Images
            </label>
            <ImageUpload onChange={handleImageChange} />
          </div>

          <div className="col-span-3">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold w-full"
            >
              Add Product
            </button>
          </div>
        </form>

        {showSuccessModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-md">
              <p className="text-green-500 font-bold text-lg mb-4">
                Product uploaded successfully!
              </p>
              <button
                onClick={() => setShowSuccessModal(false)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default ProductForm;
