import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Modal = ({ message, onClose, isClosable }) => {
  return (
    <div className="modal fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="modal-content bg-white p-4 rounded shadow-lg">
        {isClosable && (
          <button onClick={onClose}>
            <AiOutlineClose size={36} className="font-extrabold " />
          </button>
        )}
        <div className="flex justify-between items-center texte-center p-10 mb-4">
          <p className="text-xl font-bold">{message}</p>
        </div>
        <Link to="/cart">
          <button className="bg-purple-900 hover:bg-purple-600 text-white px-6 py-3 rounded font-bold text-lg">
            Go to Cart
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Modal;
