import React from "react";

const LoadingCover = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white flex justify-center items-center">
      <svg className="animate-ping h-20 w-20 fill-current text-blue-500 mx-auto" viewBox="0 0 24 24">
        <circle
          className="opacity-75"
          cx="12"
          cy="12"
          r="10"
          strokeWidth="4"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 12 12"
            to="360 12 12"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingCover;
