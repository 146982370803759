import React from "react";

const DeliveryInformationCard = ({
  address,
  city,
  state,
  country,
  postalCode,
  formattedDeliveryDate,
}) => {
  return (
    <div className="bg-transparent p-4 rounded-lg">
      <h3 className="font-bold text-xl mb-2">Delivery Information</h3>
      <p>
        <strong>Estimated Delivery Date:</strong> {formattedDeliveryDate}
      </p>
      <p>
        <strong>Delivery Method:</strong> Standard Shipping
      </p>
      <p>
        <strong>Delivery Address:</strong> {address}, {city}, {state}, {country}
        , {postalCode}
      </p>
    </div>
  );
};

export default DeliveryInformationCard;
