import React from "react";
import Ogaslogo from "../../assets/Ogas_Logo.webp";
import { Link } from "react-router-dom";

const Logo = ({ src, alt }) => (
  <div className="text-white text-2xl font-bold">
    <Link to="/">
      <img src={Ogaslogo} width={100} height={50} className="hidden lg:block md:block" alt={"ogas desktop logo"} />
      <img src={Ogaslogo} width={50} height={50} className="hidden max-[768px]:block ml-0 pl-0" alt={"ogas mobile logo"} />
    </Link>
  </div>
);

export default Logo;
