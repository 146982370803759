import React, { useState } from "react";

const ImageUpload = ({ onChange }) => {
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleInputChange = (e) => {
    const { files } = e.target;

    const newImages = Array.from(files);
    onChange(newImages);

    const newPreviews = newImages.map((file) => URL.createObjectURL(file));
    setImagePreviews(newPreviews);
  };

  return (
    <div>
      <input
        type="file"
        id="images"
        name="images"
        accept="image/*"
        multiple
        onChange={handleInputChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
      <div className="mt-4 grid grid-cols-3 gap-4">
        {imagePreviews.map((image, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            key={index}
            src={image}
            alt={`Image Preview ${index}`}
            className="w-full h-32 object-cover rounded-md"
          />
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
