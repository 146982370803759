import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";
import { ref as storageRef } from "firebase/storage";
import app from "../firebase";
import BlogNavbar from "./BlogNavBar";
import PostCard from "./PostCard";
import { storage } from "../firebase";

const firestore = getFirestore(app);

const Blog = () => {
  // State to store the list of posts
  const [posts, setPosts] = useState([]);
  //   const [viewCount, setViewCount] = useState(post.views);

  // Handle click on "Read more" button
//   const handleReadMore = async (clickedPost) => {
//     // Update the view count of the clicked post
//     const updatedPosts = posts.map((p) =>
//       p.id === clickedPost.id ? { ...p, views: p.views + 1 } : p
//     );

//     // Save the updated view count to Firestore
//     const postDocRef = doc(
//       firestore,
//       "blog",
//       clickedPost.title
//     );
//     await updateDoc(postDocRef, { views: clickedPost.views + 1 });

//     // Update the state with the new posts array
//     setPosts(updatedPosts);
//   };

  // useEffect to fetch posts data when the component mounts
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(firestore, "blog"); // 'blog' is the collection name in Firestore
        const postsSnapshot = await getDocs(postsCollection);
        const postsData = await Promise.all(
          postsSnapshot.docs.map(async (doc) => {
            const postData = doc.data();

            // Construct the image URL based on the post title
            const imageName = `${postData.title.replace(/\s/g, "")}_${
              postData.title.length
            }`;
            const imageRef = storageRef(
              storage,
              `Blogpost-images/${imageName}`
            );
            const imageUrl = await getDownloadURL(imageRef);

            return { id: doc.id, ...postData, imageUrl };
          })
        );
        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching posts: ", error);
      }
    };

    fetchPosts();
  }, []); // Empty dependency array ensures the effect runs only once on mount // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="dark:bg-gray-800">
      <BlogNavbar />
      <div className="bg-gray-900 text-white py-16 bg-black">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4">Welcome to my Blog</h1>
          <p className="text-lg mb-8">Discover amazing posts on my blog</p>
          <a
            href="#"
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-full font-bold text-lg"
          >
            Read Now
          </a>
        </div>
      </div>{" "}
      <div className="container mx-auto p-8">
        <div className="flex">
          {/* Greater column - Displaying blog posts */}
          <div className="w-2/3 pr-8">
            <ul>
              {posts.map((post) => (
                <li key={post.id} className="mb-8 mt-10">
                  {/* Render the PostCard component for each post */}
                  <PostCard
                    post={post}
                    // handleReadMore={() => handleReadMore(post)}
                  />
                </li>
              ))}
            </ul>
          </div>

          {/* Lesser column - Displaying featured posts, announcements, and categories */}
          <div className="w-1/3">
            {/* Featured Posts */}
            <div className="mb-4">
              <h2 className="text-2xl font-bold mb-2">Featured Posts</h2>
              {/* Display thumbnails and titles of the latest posts */}
              <ul>
                {posts.map((post) => (
                  <li key={post.id} className="flex mb-2">
                    <Link to={`/blog/${post.title}`}>
                      <img
                        src={post.imageUrl}
                        alt={post.title}
                        className="w-26 h-24 object-cover mr-2 rounded"
                        // onClick={handleReadMore}
                      />
                      <p>{post.title}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Latest Announcements */}
            {/* Blog Categories */}
            {/* ... (remaining code for announcements and categories) */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
