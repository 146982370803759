import React from "react";

const categories = [
  "T-shirt",
  "Sweatshirt",
  "Joggers",
  // Add more categories as needed
];

const prices = [
  { label: "Under $10", value: "under10" },
  { label: "$10 to $30", value: "10to30" },
  { label: "$30 to $50", value: "30to50" },
  { label: "$50 to $70", value: "50to70" },
  { label: "Over $70", value: "over70" },
];

const CategoryFilter = ({ selectedCategory, setSelectedCategory, selectedPrices, setSelectedPrices }) => {
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handlePriceChange = (value) => {
    setSelectedPrices((prevPrices) =>
      prevPrices.includes(value)
        ? prevPrices.filter((price) => price !== value)
        : [...prevPrices, value]
    );
  };

  return (
    <div className="w-64 p-4 rounded-lg bg-white overflow-auto mr-16">
      <h2 className="text-xl font-bold mb-4">Categories</h2>
      <ul className="mb-4">
        {categories.map((category) => (
          <li key={category} className="mb-2">
            <button
              className={`w-full text-left py-1 px-2 rounded transition duration-300 ${
                selectedCategory === category ? "bg-gray-200 font-bold" : ""
              } hover:bg-gray-300`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
      <h2 className="text-xl font-bold mb-4">Price</h2>
      <ul>
        {prices.map((price) => (
          <li key={price.value} className="mb-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 font-bold focus:ring-purple-700 text-purple-600"
                value={price.value}
                checked={selectedPrices.includes(price.value)}
                onChange={() => handlePriceChange(price.value)}
              />
              <span className="ml-2">{price.label}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryFilter;
