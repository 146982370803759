import "./App.css";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore, auth } from "./firebase";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./components/routes";
import { onAuthStateChanged } from "firebase/auth";
import NotFound from "./404";

function App() {
  const [brandsData, setBrandsData] = useState([]);
  const [brandName, setBrandName] = useState("ogascounty");
  const [user, setUser] = useState(null);
  const [currencyCode, setCurrencyCode] = useState("$");
  const [currentRate, setCurrentRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("USD");

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) setUser(user);
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [auth]);

  useEffect(() => {
    const checkData = async () => {
      // Check if country rates exist in sessionStorage
      const countryRates = sessionStorage.getItem("countryRate");
      if (!countryRates) {
        // Fetch location data and rates if country rates do not exist
        await fetchAndSaveRatesData();
      } else {
        // Parse sessionStorage data
        const { country } = JSON.parse(countryRates);
        await fetchLocationData(country);
      }
    };

    const fetchAndSaveRatesData = async () => {
      try {
        // Fetch location data
        const locationData = await fetchLocationData();
        const country = locationData?.country.name;

        // Fetch rates data
        const ratesData = await fetchRatesData();
        const currencyCode = getCurrencyCode(country);
        const currencySymbol = getCurrencySymbol(currencyCode);
        setCurrencySymbol(currencySymbol);
        setCurrencyCode(currencyCode);
        const rate = ratesData?.rates[currencyCode];
        setCurrentRate(rate);

        // Save rate, country, and currency code to sessionStorage
        sessionStorage.setItem(
          "countryRate",
          JSON.stringify({
            country,
            rate,
            countryCode: currencyCode,
            currencySymbol: getCurrencySymbol(currencyCode),
          })
        );

        // Log sessionStorage data
        // console.log(
        //   "SessionStorage data:",
        //   sessionStorage.getItem("countryRate")
        // );
      } catch (error) {
        console.error("Error fetching and saving rates data:", error);
      }
    };

    const fetchLocationData = async () => {
      try {
        const key = "112f7159f7574f8c8cc3be627aa13f53";
        const url = "https://api.geoapify.com/v1/ipinfo";
        const response = await fetch(`${url}?apiKey=${key}`);
        const data = await response.json();
        if (!data) {
          return;
        }
        // console.log("Location data:", data);
        return data;
      } catch (error) {
        console.error("Error fetching location data:", error);
        throw error;
      }
    };

    const fetchRatesData = async () => {
      try {
        // Fetch data from Firestore or external API
        // Replace this with your actual implementation
        // For example, fetching rates from Firestore
        const currencyRatesCollection = collection(firestore, "currencyRates");
        const snapshot = await getDocs(currencyRatesCollection);
        const ratesData = snapshot.docs.map((doc) => doc.data())[0]; // Assuming there's only one document
        // console.log("General rates data:", ratesData);
        return ratesData;
      } catch (error) {
        console.error("Error fetching rates data:", error);
        throw error;
      }
    };

    const getCurrencyCode = (country) => {
      // Set currency code based on country
      let currencyCode;

      if (country === "Nigeria") {
        currencyCode = "NGN";
      } else if (country === "United States" || country === "U.S.") {
        currencyCode = "USD";
      } else {
        // Default currency code if not Nigeria or USA
        currencyCode = "USD"; // or any other default currency code
      }
      // console.log("Current country code:", currencyCode);
      return currencyCode;
    };

    // Function to get currency symbol based on currency code
    const getCurrencySymbol = (currencyCode) => {
      // Define mapping of currency code to currency symbol
      const currencySymbols = {
        NGN: "₦",
        USD: "$",
        // Add more currency code to symbol mappings as needed
      };

      // Get currency symbol corresponding to the currency code
      return currencySymbols[currencyCode] || ""; // Default to empty string if no symbol found
    };

    // Initial call to checkData
    checkData();
  }, []);

  useEffect(() => {
    const fetchBrandsData = async () => {
      try {
        const brandsCollection = collection(firestore, "brands");
        const snapshot = await getDocs(brandsCollection);
        const brandsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log("Fetched Brands Data:", brandsData);
        setBrandsData(brandsData);

        // Process each brand document
        brandsData.forEach((brand) => {
          if (brand.type === "default") {
            // Do nothing or handle default brand behavior
            // console.log(`Default brand found: ${brand.id}`);
            setBrandName(brand);
          } else {
            // Save brand data or pass it down to child components
            // console.log(`Custom brand found: ${brand.id}`);
            // Example: Save to localStorage
            setBrandName(brand);
            localStorage.setItem(`brandData`, JSON.stringify(brand));
          }
        });
      } catch (error) {
        console.error("Error fetching brands data:", error);
      }
    };

    // Fetch brands data on component mount
    fetchBrandsData();
  }, []);

  // // index.js or App.js

  // if ("serviceWorker" in navigator) {
  //   window.addEventListener("load", () => {
  //     navigator.serviceWorker
  //       .register("./service-worker.js")
  //       .then((registration) => {
  //         // console.log(
  //         //   "Service Worker registered with scope: ",
  //         //   registration.scope
  //         // );
  //       })
  //       .catch((error) => {
  //         // console.log("Service Worker registration failed: ", error);
  //       });
  //   });
  // }

  return (
    <div>
      {/* <app /> */}
      <Router>
        <Routes>
          {routes.map((route) => (
            <>
              <Route
                key={route.path}
                path={route.path}
                element={
                  <route.component
                    brandsData={brandsData}
                    brandName={brandName}
                    currencyCode={currencyCode}
                    currentRate={currentRate}
                    currencySymbol={currencySymbol}
                    user={user}
                  />
                }
              />
              <Route path="*" element={<NotFound />} />
            </>
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
