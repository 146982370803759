import React, { useState, useRef, useEffect } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import LoadingCover from "../LoadingCover";

const DEBOUNCE_DELAY = 100;

const SearchBox = ({ products }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const [loading, setLoading] = useState(false); // State to control loading cover visibility
  let navigate = useNavigate();
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  // console.log("This is products params:", products);

  const handleSearch = debounce((query) => {
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(query.toLowerCase())
    );
    setSearchQuery(query);
    sessionStorage.setItem("filteredResults", JSON.stringify(filteredProducts));
  }, DEBOUNCE_DELAY);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    handleSearch(query);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate("/queryresult");
      setSearchQuery("");
    }
  };

  // Function to handle product selection
  const handleProductSelection = async (product) => {
    const productTitle = product.title.replace(/\s+/g, "-");
    setSearchQuery(product.title);
    navigate(`/shop/${productTitle}`);
    setShowDropdown(false); // Hide dropdown after selection
    setSearchQuery("");

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 3000);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        type="text"
        id="searchQuery"
        name="searchQuery"
        value={searchQuery}
        placeholder="Search Ogascounty..."
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        className="flex-1 mt-1 p-1 border border-gray-300 rounded-sm text-dark"
        style={{ width: "100%" }}
        onClick={() => setShowDropdown(true)}
      />
      {showDropdown && (
        <div className="absolute z-10 bg-white mt-1 w-full max-h-48 overflow-y-auto border border-gray-300 rounded-md shadow-md">
          {products
            ?.filter((product) =>
              product.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .slice(0, 10) // Limit to at most 10 items
            .map((product) => (
              <div
                key={product.id}
                className="p-2 hover:bg-gray-100 border-b cursor-pointer"
                onClick={() => handleProductSelection(product)}
              >
                {product.title}
              </div>
            ))}
        </div>
      )}
      <button
        type="submit"
        className="bg-purple-900 hidden hover:bg-purple-700 mt-1 text-white font-bold py-2 px-4 rounded-md"
      >
        Search
      </button>
      {loading && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <LoadingCover/>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
