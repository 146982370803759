// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjbd00v2uK2lYaK2cqWFtameU4e9WhmWM",
  authDomain: "ogasplanet.firebaseapp.com",
  projectId: "ogasplanet",
  storageBucket: "ogasplanet.appspot.com",
  messagingSenderId: "102715679860",
  appId: "1:102715679860:web:53090bbada4ab6c74f1d21",
  measurementId: "G-GRXB7R6QTG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export default app;

