import React, { useState, useEffect } from "react";
import { fetchSingleProductData } from "../../functions";
import Counter from "../Counter";
import Modal from "../Modal";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import CustomerReviews from "../../../Customer/CustomerReviews";
import { firestore } from "../../../../firebase";
import ProductOptions from "../../ProductOptions";
import ImageGallery from "../../ImageGallery";
import useMetadata from "../../../../hooks/UseMetadata";
import PrintifyCheckout from "./Checkout";
import StarRating from "../../../StarRating";

const PrintifyProductDetails = ({
  productId,
  user,
  currencyCode,
  currentRate,
  currencySymbol,
}) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [variantId, setVariantId] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [price, setPrice] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false); // For add to cart message
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false); // For checkout modal
  const [modalMessage, setModalMessage] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [reviews, setReviews] = useState(null);

  const cleanedCurrentRate = currentRate === undefined ? 1 : currentRate;
  const cleaedCurrencyCode = currencyCode === undefined ? "$" : currencyCode;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await fetchSingleProductData(productId);
        const reviewCollection = collection(firestore, "reviews");
        const reviewSnapshot = await getDocs(reviewCollection);
        const reviewsData = [];
        let totalRating = 0;

        reviewSnapshot.forEach((docSnap) => {
          const reviewData = docSnap.data();
          if (reviewData.product === data.title) {
            reviewsData.push(reviewData);
            totalRating += reviewData.rating;
          }
        });

        const average =
          reviewsData.length > 0 ? totalRating / reviewsData.length : 0;
        setAverageRating(average.toFixed(1));
        setReviews(reviewsData);
        setProduct(data);
        setSelectedSize(data?.variants[0]?.title.split(" / ")[1] || null);
        setSelectedColor(data?.variants[0]?.title.split(" / ")[0] || null);
        setVariantId(data?.variants[0]?.id || null);
        setAvailableQuantity(data?.variants[0]?.quantity || 0);
        setPrice(parseInt(data?.variants[0]?.price || null));
        setSelectedMainImage(data?.images[0]?.src || null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const addToCart = async () => {
    try {
      const cartData = JSON.parse(localStorage.getItem("cartData")) || [];
      const existingProductIndex = cartData?.findIndex((item) => {
        if (item?.id !== product.id) return false;
        if (item?.variantId !== variantId) return false;
        if (selectedSize && item?.size !== selectedSize) return false;
        if (selectedColor && item?.color !== selectedColor) return false;
        return true;
      });

      if (existingProductIndex !== -1) {
        cartData[existingProductIndex].quantity += quantity;
      } else {
        cartData.push({
          id: product.id,
          name: product.title,
          price: price / 100,
          size: selectedSize ? selectedSize : "One Size",
          color: selectedColor ? selectedColor : "One color",
          image: product.images[0].src,
          quantity: quantity,
          line_items: [
            {
              id: product.id,
              variantId: variantId,
              quantity: quantity,
            },
          ],
          shipping_method: 1,
          is_economy_shipping: product.is_economy_shipping_eligible,
          is_printify_express: product.is_printify_express_enabled,
          send_shipping_notification: true,
        });
      }

      localStorage.setItem("cartData", JSON.stringify(cartData));
      setModalMessage("Product added to cart!");
      setIsModalOpen(true);

      if (user) {
        const userDocRef = doc(firestore, "users", user.email);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        userData.cart = cartData;
        await updateDoc(userDocRef, userData);
      }

      setTimeout(() => {
        setIsModalOpen(false);
      }, 6000);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleBuyNow = () => {
    setIsCheckoutOpen(true); // Open the checkout modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeCheckout = () => {
    setIsCheckoutOpen(false);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const selectedVariant = product.variants.find(
      (v) => v.title === `${selectedColor} / ${size}`
    );
    if (selectedVariant) {
      setVariantId(selectedVariant.id);
      setPrice(selectedVariant.price);
      setAvailableQuantity(selectedVariant.quantity);
      const setImage = product.images.find((img) =>
        img.variant_ids.includes(selectedVariant.id)
      );
      setSelectedMainImage(setImage?.src || product.images[0]?.src || null);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const selectedVariant = product.variants.find(
      (v) => v.title === `${color} / ${selectedSize}`
    );
    if (selectedVariant) {
      setVariantId(selectedVariant.id);
      setPrice(selectedVariant.price);
      setAvailableQuantity(selectedVariant.quantity);
      const setImage = product.images.find((img) =>
        img.variant_ids.includes(selectedVariant.id)
      );
      setSelectedMainImage(setImage?.src || product.images[0]?.src || null);
    }
  };

  if (error) {
    return <div className="text-center mt-4 text-red-500">Error: {error}</div>;
  }

  if (isCheckoutOpen) {
    return (
      <PrintifyCheckout
        product={{
          id: product.id,
          title: product.title,
          price: price / 100,
          size: selectedSize,
          color: selectedColor,
          quantity,
          image: selectedMainImage,
        }}
        onBack={() => setIsCheckoutOpen(false)}
      />
    );
  }

  return (
    <div className="container mx-2 md-mx-auto p-2 lg:p-20">
      {product && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* Column for images */}
            <ImageGallery
              images={product.images}
              selectedMainImage={selectedMainImage}
              setSelectedMainImage={setSelectedMainImage}
            />

            {/* Column for product details */}
            <div>
              <h1 className="text-3xl font-bold text-left mb-4">
                {product.title}
              </h1>
              <div className="text-gray-900 mb-4 font-bold text-xl"><StarRating rating={averageRating} /></div>
              <div className="text-lg mb-4">
                <p className="font-bold">
                  {price
                    ? `${cleaedCurrencyCode}${
                        (price / 100) * cleanedCurrentRate
                      }`
                    : ""}
                </p>
                <hr className="py-4" />
              </div>
              <ProductOptions
                options={product.options}
                selectedSize={selectedSize}
                selectedColor={selectedColor}
                handleSizeChange={handleSizeChange}
                handleColorChange={handleColorChange}
              />

              <Counter quantity={quantity} setQuantity={setQuantity} />
              <div className="flex items-center justify-between space-x-10 py-4">
                <button
                  className="w-full bg-purple-600 hover:bg-purple-800 text-white px-6 mt-5 py-3 rounded font-bold text-lg"
                  onClick={addToCart}
                  disabled={!variantId}
                >
                  Add to Cart
                </button>
                <button
                  className="w-full bg-yellow-600 hover:bg-yellow-800 text-white px-6 mt-5 py-3 rounded font-bold text-lg"
                  onClick={handleBuyNow}
                  disabled={!variantId}
                >
                  Buy Now
                </button>
              </div>

              {isModalOpen && (
                <Modal
                  onClose={closeModal}
                  message={modalMessage}
                  isClosable={true}
                />
              )}
            </div>
          </div>
          <div className="bg-white rounded px-8 pt-2 pb-6 text-wrap">
            <div className="flex items-center justify-between py-4 mb-4 border-b border-gray-100">
              <h1 className="font-bold text-2xl">Product Description</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
          <CustomerReviews reviews={reviews} averageRating={averageRating} />
        </>
      )}
    </div>
  );
};

export default PrintifyProductDetails;
