import React from "react";

const ImageGallery = ({ images, selectedMainImage, setSelectedMainImage }) => (
  <div className="flex flex-col items-center">
    <img
      src={selectedMainImage ? selectedMainImage : images[0].src}
      alt="Product"
      className="w-96 h-auto max-w-400 bg-inherit shadow-md object-contain rounded-md mb-4"
    />
    <div className="grid grid-cols-6 gap-4">
      {images.slice(0, 5).map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt="Product Thumbnail"
          className="w-24 h-24 object-contain rounded hover:shadow-md mb-4 cursor-pointer"
          onClick={() => setSelectedMainImage(image.src)}
        />
      ))}
    </div>
  </div>
);

export default ImageGallery;
