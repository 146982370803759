import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import Modal from "./Modal";
import NewletterImg from "../../assets/close-up-portrait-gorgeous-young-woman 3.png";

const EmailNewsletter = () => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleChange = (e) => {
    console.log("Input changed: ", e.target.value); // Debugging log
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setModalMessage("Subscribing to newsletter...");
    setIsModalOpen(true);

    const formData = { email, type: "subscribe" };

    try {
      const response = await fetch(
        "https://6pnrwop7oe6si6pjbhdpt7gjye0pmalq.lambda-url.us-east-1.on.aws/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setModalMessage("Subscription successful!");
        setEmail("");
      } else {
        setModalMessage("Failed to subscribe.");
      }
    } catch (error) {
      console.error("Error:", error);
      setModalMessage("Error occurred while subscribing.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage("");
  };

  return (
    <div className="bg-gradient-to-t from-purple-700 to-transparent py-8 px-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center text-white">
        <div className="w-full md:w-1/2 mb-4 md:mb-0 ml-20 relative item-end">
          <img src={NewletterImg} alt="Newsletter" className="w-60 h-auto" />
          <div className="absolute bottom-0 left-0 right-0 h-20"></div>
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center md:items-start">
          {/* Newsletter Form */}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full md:w-full bg-inherit"
          >
            <h1 className="text-4xl font-bold">Join Our Newsletter</h1>
            <label htmlFor="email" className="mb-6 text-white">
              Sign up for deals, promotions and new products.
            </label>
            <div className="relative mb-2 flex flex-col md:flex-row items-center md:items-start w-full text-white">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Your email"
                className="px-4 py-2 bg-transparent border outline-transparent focus:border-none text-white rounded-sm mb-2 md:mb-0 md:mr-2 w-96 max-[767px]:w-auto"
                required
                
              />
              <button
                type="submit"
                className="bg-transparent absolute max-[767px]:relative max-[767px]:bg-yellow-500 min-[767px]:left-72 text-white py-2 px-4 rounded hover:bg-yellow-600 w-full md:w-auto"
              >
                Subscribe
              </button>
            </div>
          </form>

          {/* Social Icons */}
          <div className="flex items-center mt-4">
            <Link to="#" className="text-gray-200 hover:text-gray-300 pr-2">
              <FiFacebook size={24} color="#3b5998" />
            </Link>
            <Link to="#" className="text-gray-200 hover:text-gray-300 px-2">
              <FiTwitter size={24} color="#1da1f2" />
            </Link>
            <Link to="#" className="text-gray-200 hover:text-gray-300 px-2">
              <FiInstagram size={24} color="#c13584" />
            </Link>
          </div>
        </div>
      </div>

      {isModalOpen && <Modal message={modalMessage} onClose={closeModal} />}
    </div>
  );
};

export default EmailNewsletter;
