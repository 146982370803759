import { useEffect } from "react";

const useMetadata = (metadata) => {
  useEffect(() => {
    const updateMetaTagContent = (metaTagName, newContent) => {
      const metaTag = document.head.querySelector(
        `meta[name="${metaTagName}"]`
      );
      if (metaTag) {
        metaTag.setAttribute("content", newContent);
      }
    };

    document.title = metadata.title;
    updateMetaTagContent("description", metadata.description);
    updateMetaTagContent("og:title", metadata.og.title);
    updateMetaTagContent("og:description", metadata.og.description);
    updateMetaTagContent("og:image", metadata.og.image);
    updateMetaTagContent("og:url", metadata.og.url);
    updateMetaTagContent("og:type", metadata.og.type);
    updateMetaTagContent("twitter:card", metadata.twitter.card);
    updateMetaTagContent("twitter:title", metadata.twitter.title);
    updateMetaTagContent("twitter:description", metadata.twitter.description);
    updateMetaTagContent("twitter:image", metadata.twitter.image);
    updateMetaTagContent("twitter:site", metadata.twitter.site);
    const canonicalLink = document.head.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.setAttribute("href", metadata.canonical);
    }
    updateMetaTagContent("charset", metadata.charset);
    document.documentElement.lang = metadata.lang;
    updateMetaTagContent("keywords", metadata.keywords);
    updateMetaTagContent("author", metadata.author);
    updateMetaTagContent("robots", metadata.robots);
    updateMetaTagContent("theme-color", metadata["theme-color"]);
  }, [metadata]);
};

export default useMetadata;
