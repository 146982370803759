import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import { firestore } from "../../../firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import AdminLayout from "../AdminLayout";
import UserList from "./UserList";
import UserInfo from "./UserInfo";
import UserForm from "./UserForm";

const ViewUsers = () => {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentUser]);

  const deleteUser = async (userId) => {
    try {
      await deleteDoc(doc(firestore, "users", userId));
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const fetchUserInfo = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.error(`User document with ID ${userId} does not exist.`);
        return;
      }

      setSelectedUser({ id: userId, ...userDoc.data() });
    } catch (error) {
      console.error("Error fetching user information: ", error);
    }
  };

  const saveUser = async (user) => {
    try {
      if (user.email) {
        await setDoc(doc(firestore, "users", user.email), user);
      } else {
        await addDoc(collection(firestore, "users"), user);
      }
      setIsEditing(false);
      fetchUsers();
    } catch (error) {
      console.error("Error saving user: ", error);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  return (
    <AdminLayout>
      <div className="container mx-auto my-16">
        <h2 className="text-3xl font-bold mb-8">Admin - Users Management</h2>
        <div className="flex justify-between mb-4">
          <div>Total Users: {users.length}</div>
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 text-white px-3 py-1 rounded-full hover:bg-blue-600"
          >
            Add User
          </button>
        </div>
        <UserList
          users={users}
          fetchUserInfo={fetchUserInfo}
          deleteUser={deleteUser}
          handleEditUser={handleEditUser}
        />
        {selectedUser && !isEditing && (
          <UserInfo
            user={selectedUser}
            closeUserInfo={() => setSelectedUser(null)}
          />
        )}
        {isEditing && (
          <UserForm
            user={selectedUser}
            saveUser={saveUser}
            closeForm={() => {
              setIsEditing(false);
              setSelectedUser(null);
            }}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default ViewUsers;
