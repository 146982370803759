import React, { useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import StarRating from "../../../StarRating";
import { formatNumber } from "../../functions";
import Modal from "./Modal";
import PrintifyProductDetails from "../ProductModal/ProductDetails";
import useWindowSize from "../../../../hooks/UseWindowSize";

const ProductCard = ({ product, isHotSale }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize();

  const cardDimensions = useRef("");
  const cardCover = useRef("");
  const productTitle = useRef("");
  const productPrice = useRef("");

  useMemo(() => {
    if (width > 0 && width < 600) {
      cardDimensions.current = "w-20 max-h-20";
      cardCover.current = "h-40 w-32";
      productTitle.current = "text-xs";
      productPrice.current = "text-xs";
    } else if (width > 600 && width < 1024) {
      cardDimensions.current = "w-full max-h-60";
      cardCover.current = "h-86 max-w-72";
      productTitle.current = "text-sm";
      productPrice.current = "text-sm";
    } else if (width > 1024 && width < 2000) {
      cardDimensions.current = "w-full max-h-72";
      cardCover.current = "h-86 max-w-86";
      productTitle.current = "text-lg";
      productPrice.current = "text-lg";
    }
  }, [width]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const shortenedTitle = useMemo(() => {
    if (width > 0 && width < 600) {
      return `${product.title.substring(0, 15)}...`;
    } else if (width > 600 && width < 1024) {
      return `${product.title.substring(0, 20)}...`;
    } else {
      return product.title;
    }
  }, [width, product.title]);

  const findFirstValidImage = (images) => {
    for (let image of images) {
      if (image.src && (image.is_default || image.is_selected_for_publishing)) {
        return image.src;
      } else if (Array.isArray(image)) {
        const nestedImage = findFirstValidImage(image);
        if (nestedImage) {
          return nestedImage;
        }
      }
    }
    return null;
  };

  const productImage = useMemo(
    () => findFirstValidImage(product?.images || []),
    [product]
  );

  if (!productImage) return null;

  const hotSale = product?.blueprint_id > 1000;

  return (
    <div className={`relative mx-2 my-2 flex flex-col ${cardCover.current}`}>
      <div
        onClick={handleOpenModal}
        className="group p-2 hover:shadow-md rounded cursor-pointer"
      >
        <div className={`${cardDimensions.current} mb-2 relative bg-gray-100`}>
          {isHotSale && (
            <p className="bg-purple-200 my-2 p-1 font-bold rounded text-sm absolute">
              HOT
            </p>
          )}
          <img
            src={productImage}
            alt={product?.title}
            className="w-full h-full object-contain"
            width={"100%"}
            height={"100%"}
          />
          <div className="absolute inset-0 flex items-end justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <Link
              to="#"
              className="bg-purple-700 text-white py-1 px-4 rounded-md text-xs transform translate-y-full group-hover:translate-y-0 transition-transform duration-300"
            >
              View Product
            </Link>
          </div>
        </div>
        <div className="flex-grow flex flex-col mt-1">
          <div className="flex-grow flex items-start justify-start">
            <div
              className={`${productTitle.current} font-bold text-start text-pretty`}
            >
              <StarRating rating={product.averageRating} />
              {shortenedTitle}
            </div>
          </div>
          <div className="flex justify-between items-start">
            <p className={`${productPrice.current} font-bold text-purple-900`}>
              ${formatNumber(product?.variants[0]?.price / 100)}
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <PrintifyProductDetails productId={product.title} />
      </Modal>
    </div>
  );
};

export default React.memo(ProductCard);
