import React from "react";

const NoResultsSVG = () => {
  return (
    <svg width="200" height="80">
      <text x="0" y="50" fontSize="20" fontWeight="bold">
        Product Not Found
      </text>
    </svg>
  );
};

export default NoResultsSVG;
