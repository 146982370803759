// Modal.js
import React from "react";

const Modal = ({
  isOpen,
  closeModal,
  title,
  cartItems,
  customerInfo,
  reviews,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-md">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>

        {/* Display Customer Contact Information */}
        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">
            Customer Contact Information
          </h3>
          <p>
            <span className="font-bold">Name:</span> {customerInfo.customerName}
          </p>
          <p>
            <span className="font-bold">Phone:</span> {customerInfo.phone}
          </p>
          <p>
            <span className="font-bold">Address:</span> {customerInfo.address},{" "}
            {customerInfo.city}, {customerInfo.state}, {customerInfo.country},{" "}
            {customerInfo.postalCode}
          </p>
        </div>

        {/* Display Cart Items */}
        <div className="mb-4">
          <h3 className="text-xl font-bold mb-2">Cart Items</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 mb-4">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Product Price
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cartItems.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.productName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.productPrice}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.productQuantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Display Reviews */}
        {Array.isArray(reviews) && reviews.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xl font-bold mb-2">Reviews</h3>
            <div className="overflow-x-auto">
              {reviews.map((review, index) => (
                <div key={index} className="mb-4">
                  <h4 className="text-lg font-bold">{review.product}</h4>
                  <p>
                    <span className="font-bold">Rating:</span> {review.rating}
                  </p>
                  <p>
                    <span className="font-bold">Review Date:</span>{" "}
                    {review.reviewDate &&
                      new Date(
                        review.reviewDate.seconds * 1000
                      ).toLocaleString()}
                  </p>{" "}
                  <p>
                    <span className="font-bold">Review Message:</span>{" "}
                    {review.reviewMessage}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        <button
          onClick={closeModal}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
