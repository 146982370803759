import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import BlogNavbar from "./BlogNavBar";
import Footer from "./Footer/Footer";
import LoadingCover from "./LoadingCover";

const BlogLayout = ({ children }) => {
  // Extract the pathname from the current location
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 3000; // 2 seconds

    const loadPage = async () => {
      // Simulate data fetching or any other asynchronous operations
      await new Promise((resolve) => setTimeout(resolve, delay));

      // After the delay, set isLoading to false to hide the LoadingCover
      setIsLoading(false);
    };

    loadPage();
  }, []);

  return (
    <div>
      <BlogNavbar />
      <div className="container mx-auto mt-8">
        {/* Breadcrumbs */}
        <div className="mb-4">
          <Link to="/">Home</Link>
          {pathSegments.map((segment, index) => (
            <span key={index}>
              {" "}
              /{" "}
              <Link to={`/${pathSegments.slice(0, index + 1).join("/")}`}>
                {segment}
              </Link>
            </span>
          ))}
        </div>

        {/* Page Content */}
        <div className="content">{children}</div>
        {isLoading && <LoadingCover />}
      </div>
      <Footer />
    </div>
  );
};

export default BlogLayout;
