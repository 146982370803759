import React from "react";
import {
  FaShippingFast,
  FaMoneyBillWave,
  FaLock,
  FaHeadset,
} from "react-icons/fa";

const InfoCards = () => {
  return (
    <div className="container mx-auto py-4">
      <div className="flex flex-wrap justify-around">
        <div className="flex flex-col items-start bg-gray py-10 px-4 bg-gray-200 rounded m-2 w-64 max-w-40 max-h-40">
          <FaShippingFast size={48} className="text-dark mb-4 inset-icon" />
          <h3 className="text-lg font-semibold">Free Shipping</h3>
          <p className="text-sm text-gray-500">Orders above $300</p>
        </div>
        <div className="flex flex-col items-start bg-gray py-10 px-4 bg-gray-200 rounded m-2 w-64 max-w-40 max-h-40">
          <FaMoneyBillWave size={48} className="text-dark mb-4 outset-icon" />
          <h3 className="text-lg font-semibold">Money Back</h3>
          <p className="text-sm text-gray-500">30 Days Guarantee</p>
        </div>
        <div className="flex flex-col items-start bg-gray py-10 px-4 bg-gray-200 rounded m-2 w-64 max-w-40 max-h-40">
          <FaLock size={48} className="text-dark mb-4 inset-icon" />
          <h3 className="text-lg font-semibold">Secure Payments</h3>
          <p className="text-sm text-gray-500">Secured By Stripe</p>
        </div>
        <div className="flex flex-col items-start bg-gray py-10 px-4 bg-gray-200 rounded m-2 w-64 max-w-40 max-h-40">
          <FaHeadset size={48} className="text-dark mb-4 inset-icon" />
          <h3 className="text-lg font-semibold">24/7 Support</h3>
          <p className="text-sm text-gray-500">Phone And Email Support</p>
        </div>
      </div>
    </div>
  );
};

export default InfoCards;
