import React, { useState, useEffect } from "react";

const UserForm = ({ user, saveUser, closeForm }) => {
  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    contactInformation: {
      address: "",
      country: "",
      // Add more fields as needed
    },
  });

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      contactInformation: {
        ...prevData.contactInformation,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUser(formData);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">{user ? "Edit User" : "Add User"}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Display Name</label>
          <input
            type="text"
            name="displayName"
            value={formData.displayName}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Address</label>
          <input
            type="text"
            name="address"
            value={formData.contactInformation.address}
            onChange={handleContactChange}
            className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium">Country</label>
          <input
            type="text"
            name="country"
            value={formData.contactInformation.country}
            onChange={handleContactChange}
            className="mt-1 block w-full border border-gray-300 px-3 py-2 rounded-md"
          />
        </div>
        {/* Add more fields as needed */}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeForm}
            className="bg-red-500 text-white px-3 py-1 rounded-full hover:bg-red-600 mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-3 py-1 rounded-full hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
