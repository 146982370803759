import React, { useState } from "react";
import banner from "../../assets/discountCard.avif";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignInComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);

      // Clear previous errors
      setError("");

      // Redirect to home/root page upon successful sign-in
      navigate("/");
    } catch (error) {
      console.error("Error signing in:", error.message);
      setError("Invalid email or password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen">
        <div
          className="w-full md:w-1/2 bg-cover bg-center h-64 md:h-auto"
          style={{
            backgroundImage: `url(${banner})`,
          }}
        >
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div className="max-w-md p-6 bg-white rounded-md w-full">
            <h2 className="text-3xl font-bold mb-4">Sign In</h2>
            <form onSubmit={handleSignIn}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className={`bg-purple-600 flex items-center justify-center hover:bg-purple-700 text-white px-4 py-2 rounded-md font-bold w-full ${isLoading && 'opacity-50 cursor-not-allowed'}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                      ></path>
                    </svg>
                    Loading...
                  </>
                ) : (
                  "Sign In"
                )}
              </button>
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </form>
            <div className="flex flex-col ">
              <p className="mt-2 text-sm text-blue-500">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
              <p className=" mt-2 text-sm text-gray-900">
                Don't have an account?{" "}
                <Link to="/signup" className="text-blue-500">
                  Create one
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignInComponent;
