import React from "react";

const StarRating = ({ rating, initialRating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <span
        key={i}
        className={i < rating ? "text-yellow-500" : "text-gray-300"}
      >
        &#9733;
      </span>
    );
  }

  return (
    <div className="">
      <div className="hidden lg:block">
        {stars}
        <span>({rating})</span>
      </div>
      <div className="hidden max-[1024px]:block">
        <span className={rating < 1 ? "text-gray-300" : "text-yellow-500"}>
          {" "}
          &#9733;
        </span>
        <span>({rating})</span>
      </div>
    </div>
  );
};

export default StarRating;
