import React, { useEffect, useState, useMemo } from "react";
import ProductCard from "../printifyShop/Products/ProductCard/ProductCard";
import SkeletonProductCard from "../printifyShop/Products/ProductCard/SkeletonProductCard";

const ProductCarousel = ({ products, loading }) => {
  const [randomProducts, setRandomProducts] = useState([]);

  // Function to select an initial random subset of products
  const getInitialRandomProducts = () => {
    const filteredProducts = products.filter(
      (product) => product.images && product.images.length > 0
    );
    const shuffledProducts = filteredProducts.sort(() => 0.5 - Math.random());
    return shuffledProducts.slice(0, 6); // Return 5 random products
  };

  // useEffect to initially set random products
  useEffect(() => {
    if (products.length > 0) {
      setRandomProducts(getInitialRandomProducts());
    }
  }, [products]);

  // Memoize the product content
  const productContent = useMemo(() => {
    return randomProducts.length > 0
      ? randomProducts.map((product) => (
          <div key={product.id} className="mx-4">
            <ProductCard product={product} />
          </div>
        ))
      : Array(3)
          .fill(0)
          .map((_, index) => (
            <div key={index}>
              <SkeletonProductCard />
            </div>
          ));
  }, [randomProducts]);

  return (
    <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-6 gap-4">
      {productContent}
    </div>
  );
};

export default ProductCarousel;
