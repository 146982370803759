import { collection, doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import app from "../../../../firebase";

const firestore = getFirestore(app);
const storage = getStorage(app);

const addProduct = async (productDetails) => {
  try {
    const productRef = doc(firestore, "products", productDetails.name.replace(/\s+/g, "-"));
    await setDoc(productRef, {
      name: productDetails.name,
      price: productDetails.price,
      description: productDetails.description,
      rating: 0.0,
      brand: productDetails.brand,
      color: productDetails.color,
      amount: productDetails.amount,
      category: productDetails.category,
      type: productDetails.type,
      images: productDetails.images,
    });

    console.log("Product added successfully!");
  } catch (error) {
    console.error("Error adding product: ", error);
    throw error;
  }
};

export default addProduct;
