import React from "react";

const formatNumber = (num) => {
  return num?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const OrderSummary = ({ cartItems, selectedShipping, shippingCost }) => {
  const locationData = JSON.parse(sessionStorage.getItem("countryRate"));
  const currencyCode = locationData?.currencySymbol;
  // const countryCode = locationData?.countryCode;
  // const currentRate = locationData?.rate;
  // const cleanedCurrentRate = currentRate === undefined ? 1 : currentRate;
  const cleanedCurrencyCode = currencyCode === undefined ? "$" : currencyCode;

  // const totalCartPrice = cartItems?.reduce(
  //   (total, item) =>
  //     total + parseInt(item.price) * parseInt(item.quantity) * currentRate,
  //   0
  // );
  // const totalPrice = Math.round(totalCartPrice + shippingCost);

  return (
    <div>
      <table className="min-w-full bg-white border rounded-sm border-gray-300 text-center">
        <thead>
          <tr>
            <th className="text-xl text-left px-6 py-2 font-bold mb-2">
              Order Summary
            </th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-16 h-16 object-cover mr-4"
                />
                <div className="text-left">
                  <div className="font-bold">{item.name}</div>
                  <div className="text-sm text-gray-500">
                    Color: {item.color}
                  </div>
                  <div className="text-sm text-gray-500">
                    Qty: {item.quantity}
                  </div>
                </div>
              </td>
              {/* <td className="py-2 px-4 border-b">
                {currencyCode}
                {formatNumber(
                  parseInt(item.price) * parseInt(item.quantity) * currentRate
                )}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 px-6 py-4 bg-white border rounded-sm border-gray-300">
        <div className="flex justify-between mb-2">
          <span>Shipping Method:</span>

          <span>
            {cartItems[0]?.shipping?.charAt(0)?.toUpperCase() +
              cartItems[0]?.shipping.slice(1)}{" "}
            Shipping
          </span>
        </div>
        <div className="flex justify-between mb-2">
          <span>Shipping Cost:</span>
          <span>
            {cleanedCurrencyCode}
            {formatNumber(cartItems[0]?.shippingCost)}
          </span>
        </div>
        <div className="flex justify-between font-bold">
          <span>Total:</span>
          <span>
            {cleanedCurrencyCode}
            {formatNumber(cartItems[0]?.totalCartPrice)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
