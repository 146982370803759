import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingCover from "../LoadingCover";
import TopNavbar from "./TopNavBar";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AdminLayout = ({ children }) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const adminDocs = await getDocs(collection(firestore, "admin"));
          const isAdminUser = adminDocs.docs.some(
            (adminDoc) => adminDoc.id === user.email
          );
  
          if (isAdminUser) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
            console.error("You're not allowed to access this page");
            navigate("/");
          }
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      } else {
        setIsAdmin(false);
        console.error("You're not allowed to access this page");
        navigate("/");
      }
    });
  
    return () => unsubscribe();
  }, [isAdmin, navigate]);
  

  useEffect(() => {
    const delay = 1000; // 1 second

    const loadPage = async () => {
      // Simulate data fetching or any other asynchronous operations
      await new Promise((resolve) => setTimeout(resolve, delay));

      // After the delay, set isLoading to false to hide the LoadingCover
      setIsLoading(false);
    };

    loadPage();
  }, []);

  return (
    <div className="flex h-screen ml-40 pl-20 mr-10">
      <TopNavbar />
      <div className="flex-1 overflow-y-auto">
        <div className="container mx-auto mt-8">
          <div className="mb-4">
            <Link to="/">Home</Link>
            {pathSegments.map((segment, index) => (
              <span key={index}>
                {" "}
                /{" "}
                <Link to={`/${pathSegments.slice(0, index + 1).join("/")}`}>
                  {segment}
                </Link>
              </span>
            ))}
          </div>

          <div className="content">{children}</div>
          {isLoading && <LoadingCover />}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
