import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import CartLink from "./CartLink";
import SearchBox from "./SearchBox";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import useWindowSize from "../../hooks/UseWindowSize";
import Logo from "./Logo";
import Dropdown from "./Dropdown";


const Navbar = ({ products }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const size = useWindowSize();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <nav className="bg-yellow-400 fixed w-full top-0 z-50 shadow-md mb-14">
        <div className="">
          {size.width > 600 ? (
            <div className="flex mx-auto items-center justify-center">
              <div className="w-1/7 mr-10 max-[777px]:mr-0 max-[768px]:mx-4">
                <Logo />
              </div>
              <div className="w-1/2 hidden min-[420px]:block">
                <SearchBox products={products} />
              </div>
              <div className="w-1/7 mx-6 max-[420px]:mx-0">
                <Dropdown />
              </div>
              <div className="w-18 hidden left-0 min-[420px]:block">
                <CartLink />
              </div>
            </div>
          ) : (
            <div className="flex items-center space-x-10 justify-between w-full">
              <div className="flex-grow py-2 pl-2">
                <SearchBox products={products} />
              </div>
              <div className="pr-6 mt-2">
                <CartLink />
              </div>
              {/* <div className="ml-2 flex items-center">
                <button onClick={openModal} className="text-dark font-medium">
                  <FaSearch size={24} />
                </button>
              </div> */}
            </div>
          )}
        </div>
      </nav>

      {/* Modal for Search on Mobile */}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md h-64 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Search
                    </h3>
                    <button
                      onClick={closeModal}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FaSearch size={24} />
                    </button>
                  </div>
                  <SearchBox products={products} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Navbar;
