import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../functions";
import Layout from "../../../Layout";
import Counter from "../Counter";
import Modal from "../Modal";
import { doc, getDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import CustomerReviews from "../../../Customer/CustomerReviews";
import { firestore } from "../../../../firebase";
import LoadingCover from "../../../LoadingCover";
import useMetadata from "../../../../hooks/UseMetadata";

const MainPrintifyProductDetails = ({
  user,
  currencyCode,
  currentRate,
  currencySymbol,
}) => {
  const { productId } = useParams();
  const productTitle = productId.replace(/-/g, " "); // Replace hyphens with spaces
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [variantId, setVariantId] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [price, setPrice] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [reviews, setReviews] = useState(null);

  // Use the useMetadata hook to set metadata
  useMetadata({
    title: product?.title || "Ogascounty | Fashion Meets Faith",
    description: product?.description?.slice(0, 120) || "Ogascounty product",
    og: {
      title: product?.title || "Ogascounty | Fashion Meets Faith",
      description: product?.description?.slice(0, 120),
      image: product?.images[0]?.src || "https://example.com/default-image.jpg",
      url: "https://ogascounty.com",
      type: "product",
    },
    twitter: {
      card: "summary_large_image",
      title: product?.title || "Ogascounty | Fashion Meets Faith",
      description: product?.description?.slice(0, 120),
      image: product?.images[0]?.src || "https://example.com/default-image.jpg",
      site: "https://ogascounty.com",
    },
    canonical: "https://ogascounty.com",
    charset: "utf-8",
    lang: "en",
    keywords: `ogascounty, ogascounty.com, ${product?.title}`,
    author: "Ogasplanet",
    robots: "index, follow",
    "theme-color": "#000000",
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        // Get the product collection from Firestore
        const productsCollection = collection(firestore, "PrintifyProducts");
        const snapshot = await getDocs(productsCollection);
        const data = snapshot.docs.map((doc) => doc.data());

        // Find the product with the matching title
        const foundProduct = data.find(
          (product) => product.title === productTitle
        );
        if (!foundProduct) {
          throw new Error("Product not found");
        }

        // Set the product details
        setProduct(foundProduct);
        setSelectedSize(
          foundProduct?.variants[0]?.title.split(" / ")[1] || null
        );
        setSelectedColor(
          foundProduct?.variants[0]?.title.split(" / ")[0] || null
        );
        setVariantId(foundProduct?.variants[0]?.id || null);
        setAvailableQuantity(foundProduct?.variants[0]?.quantity || 0);
        setPrice(foundProduct?.variants[0]?.price || null);
        setSelectedMainImage(foundProduct?.images[0]?.src || null);

        // Fetch and set reviews
        const reviewCollection = collection(firestore, "reviews");
        const reviewSnapshot = await getDocs(reviewCollection);
        const reviewsData = [];
        let totalRating = 0;

        reviewSnapshot.forEach((docSnap) => {
          const reviewData = docSnap.data();
          if (reviewData.product === foundProduct.title) {
            reviewsData.push(reviewData);
            totalRating += reviewData.rating;
          }
        });

        const average =
          reviewsData.length > 0 ? totalRating / reviewsData.length : 0;
        setAverageRating(average.toFixed(1));
        setReviews(reviewsData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productTitle]);

  const addToCart = async () => {
    try {
      const cartData = JSON.parse(localStorage.getItem("cartData")) || [];
      const existingProductIndex = cartData?.findIndex((item) => {
        if (item?.id !== product.id) return false;
        if (item?.variantId !== variantId) return false;
        if (selectedSize && item?.size !== selectedSize) return false;
        if (selectedColor && item?.color !== selectedColor) return false;
        return true;
      });

      if (existingProductIndex !== -1) {
        cartData[existingProductIndex].quantity += quantity;
      } else {
        cartData.push({
          id: product.id,
          name: product.title,
          price: price / 100,
          size: selectedSize ? selectedSize : "One Size",
          color: selectedColor ? selectedColor : "One color",
          image: product.images[0].src,
          quantity: quantity,
          line_items: [
            {
              id: product.id,
              variantId: variantId,
              quantity: quantity,
            },
          ],
          shipping_method: 1,
          is_economy_shipping: product.is_economy_shipping_eligible,
          is_printify_express: product.is_printify_express_enabled,
          send_shipping_notification: true,
        });
      }

      localStorage.setItem("cartData", JSON.stringify(cartData));
      setModalMessage("Product added to cart!");
      setIsModalOpen(true);

      if (user) {
        const userDocRef = doc(firestore, "users", user.email);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        userData.cart = cartData;
        await updateDoc(userDocRef, userData);
      }

      setTimeout(() => {
        setIsModalOpen(false);
      }, 6000);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const selectedVariant = product.variants.find(
      (v) => v.title === `${selectedColor} / ${size}`
    );
    if (selectedVariant) {
      setVariantId(selectedVariant.id);
      setPrice(selectedVariant.price);
      setAvailableQuantity(selectedVariant.quantity);
      const setImage = product.images.find((img) =>
        img.variant_ids.includes(selectedVariant.id)
      );

      setSelectedMainImage(setImage?.src || product.images[0]?.src || null);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const selectedVariant = product.variants.find(
      (v) => v.title === `${color} / ${selectedSize}`
    );
    if (selectedVariant) {
      setVariantId(selectedVariant.id);
      setPrice(selectedVariant.price);
      setAvailableQuantity(selectedVariant.quantity);

      const setImage = product.images.find((img) =>
        img.variant_ids.includes(selectedVariant.id)
      );

      setSelectedMainImage(setImage?.src || product.images[0]?.src || null);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-4">
        <LoadingCover />
      </div>
    );
  }

  if (error) {
    return <div className="text-center mt-4 text-red-500">Error: {error}</div>;
  }

  return (
    <Layout>
      <div className="container mx-auto p-4 lg:p-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {/* Column for images */}
          <div className="flex flex-col items-center">
            <img
              src={
                selectedMainImage ? selectedMainImage : product.images[0].src
              }
              alt={product.title}
              className="w-96 h-auto max-w-400 shadow-md object-contain rounded-md mb-4"
            />
            <div className="grid grid-cols-6 gap-4">
              {/* Display up to 5 additional images */}
              {product.images.slice(0, 5).map((image, index) => (
                <img
                  key={index}
                  src={image.src}
                  alt={product.title}
                  className="w-24 h-24 object-contain rounded hover:shadow-md mb-4 cursor-pointer"
                  onClick={() => setSelectedMainImage(image.src)}
                />
              ))}
            </div>
          </div>

          {/* Column for product details */}
          <div>
            <h1 className="text-3xl font-bold text-left mb-4">
              {product.title}
            </h1>
            <div className="text-lg mb-4">
              <p className="font-bold">
                {price
                  ? `${currencyCode}${formatNumber(
                      (price / 100) * currentRate
                    )}`
                  : ""}
              </p>
              <hr className="py-4" />
            </div>
            <div className="mb-4">
              {product.options?.some((opt) =>
                opt.name.toLowerCase().includes("sizes")
              ) && (
                <>
                  <p className="font-bold">Sizes:</p>
                  <select
                    value={selectedSize}
                    onChange={(e) => handleSizeChange(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md"
                  >
                    {product.options
                      .find((opt) => opt.name.toLowerCase().includes("sizes"))
                      .values.map((size) => (
                        <option key={size.id} value={size.title}>
                          {size.title}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
            <div className="mb-4">
              {product.options?.some((opt) =>
                opt.name.toLowerCase().includes("color")
              ) && (
                <>
                  <p className="font-bold">Colors:</p>
                  <select
                    value={selectedColor}
                    onChange={(e) => handleColorChange(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md"
                  >
                    {product?.options
                      ?.find((opt) => opt.name.toLowerCase().includes("color"))
                      ?.values?.map((color) => (
                        <option key={color?.id} value={color?.title}>
                          {color?.title}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>

            <Counter quantity={quantity} setQuantity={setQuantity} />
            <button
              className="w-full bg-purple-900 hover:bg-green-600 text-white px-6 mt-5 py-3 rounded font-bold text-lg"
              onClick={addToCart}
              disabled={!variantId}
            >
              Add to Cart
            </button>
            {isModalOpen && (
              <Modal message={modalMessage} onClose={closeModal} />
            )}
          </div>
        </div>
        <div className="bg-white rounded px-8 pt-2 pb-6 text-wrap">
          <div className="flex items-center justify-between py-4 mb-4 border-b border-gray-100">
            <h1 className="font-bold text-2xl">Product Description</h1>
          </div>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
        <CustomerReviews reviews={reviews} averageRating={averageRating} />
      </div>
    </Layout>
  );
};

export default MainPrintifyProductDetails;
