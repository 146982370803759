import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { getAuth } from "firebase/auth";
import banner from "../assets/discountCard.avif";
import Layout from "./Layout";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      // Clear previous errors
      setErrorMessage("");
      // Set success message
      setSuccessMessage(
        "If this email has an account with us, a password reset email sent would be sent to your inbox. within a minute."
      );
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      // Handle password reset error
      setSuccessMessage("");
      setErrorMessage("Error sending password reset email. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen">
        <div
          className="w-full md:w-1/2 bg-cover bg-center h-64 md:h-auto"
          style={{
            backgroundImage: `url(${banner})`,
          }}
        ></div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div className="max-w-md p-6 bg-white rounded-md w-full">
            <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
            <form onSubmit={handleForgotPassword}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className={`bg-purple-500 flex items-center justify-center hover:bg-purple-600 text-white px-4 py-2 rounded-full font-bold w-full ${
                  isLoading && "opacity-50 cursor-not-allowed"
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                      ></path>
                    </svg>
                    Sending...
                  </>
                ) : (
                  "Reset Password"
                )}
              </button>
              {successMessage && (
                <p className="text-green-500 mt-2">{successMessage}</p>
              )}
              {errorMessage && (
                <p className="text-red-500 mt-2">{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPasswordComponent;
