import React from 'react';
import { Link } from 'react-router-dom';
import OgasLogo from "../assets/Ogas_Logo.webp";


const BlogNavbar = () => {
  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Blog Logo or Branding */}
        <Link to="/" className="text-2xl font-bold">
        <img src={OgasLogo}
          width={100}
          height={50}
          alt="Ogascounty"
          />
        </Link>

        {/* Navigation Links */}
        <ul className="flex space-x-4">
          <li className="relative group">
            {/* Posts Dropdown */}
            <Link to="/posts" className="cursor-pointer">Posts</Link>
            <div className="hidden group-hover:block absolute left-0 mt-2 bg-white text-gray-800 p-2 rounded-md shadow-md">
              {/* Megamenu Content for Posts */}
              <div className="grid grid-cols-20 gap-8">
                <div>
                  <h3 className="text-xl font-bold mb-2">Latest Posts</h3>
                  <ul>
                    <li><Link to="/posts/latest">Latest Post 1</Link></li>
                    <li><Link to="/posts/latest">Latest Post 2</Link></li>
                    {/* Add more latest posts as needed */}
                  </ul>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-2">Popular Posts</h3>
                  <ul>
                    <li><Link to="/posts/popular">Popular Post 1</Link></li>
                    <li><Link to="/posts/popular">Popular Post 2</Link></li>
                    {/* Add more popular posts as needed */}
                  </ul>
                </div>
                <div>
                  {/* Add more megamenu sections as needed */}
                </div>
              </div>
            </div>
          </li>

          <li className="relative group">
            {/* Categories Dropdown */}
            <Link to="/categories" className="cursor-pointer">Categories</Link>
            <div className="hidden group-hover:block absolute left-0 mt-2 bg-white text-gray-800 p-2 rounded-md shadow-md">
              {/* Dropdown Content for Categories */}
              <ul>
                <li><Link to="/categories/tech">Tech</Link></li>
                <li><Link to="/categories/lifestyle">Lifestyle</Link></li>
                <li><Link to="/categories/travel">Travel</Link></li>
                {/* Add more categories as needed */}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default BlogNavbar;
