import React, { useState, useEffect } from "react";
import { firestore } from "../../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { formatNumber } from "../functions";

const CartSummary = ({
  cartItems,
  totalCartPrice,
  currentRate,
  currencySymbol,
  authUser,
}) => {
  const [selectedShipping, setSelectedShipping] = useState("standard");
  const [totalPrice, setTotalPrice] = useState(0);
  const cleanedCurrentRate = currentRate === undefined ? 1 : currentRate;
  const cleanedCurrencyCode =
    currencySymbol === undefined ? "$" : currencySymbol;

  const standardShippingPrice = 0 * cleanedCurrentRate;
  const expressShippingPrice = 10 * cleanedCurrentRate;

  const isExpressShippingEnabled = cartItems.some(
    (item) => item.is_printify_express
  );
  let shippingCost = 0 * currentRate;

  useEffect(() => {
    switch (selectedShipping) {
      case "express":
        shippingCost = expressShippingPrice;
        break;
      case "standard":
      default:
        shippingCost = standardShippingPrice;
        break;
    }
    setTotalPrice(totalCartPrice + shippingCost);
    updateCartData(shippingCost, totalCartPrice + shippingCost);
  }, [selectedShipping, totalCartPrice, currentRate]);

  const updateCartData = (shippingCost, totalPrice) => {
    const updatedCartData = cartItems.map((item) => ({
      ...item,
      shipping: selectedShipping,
      shippingCost: shippingCost,
      totalCartPrice: totalPrice,
    }));

    if (updatedCartData && updatedCartData.length > 0) {
      localStorage.setItem("cartData", JSON.stringify(updatedCartData));

      if (authUser) {
        updateFirestore(updatedCartData);
      }
    }
  };

  const updateFirestore = async (updatedCartItems) => {
    if (authUser) {
      try {
        const userCartDoc = doc(firestore, "users", authUser.email);
        await updateDoc(userCartDoc, { cart: updatedCartItems });
      } catch (error) {
        console.error("Error updating user cart data in Firestore:", error);
      }
    }
  };

  return (
    <div className="p-4 border rounded bg-inherit backdrop-blur-sm shadow-md">
      <h3 className="text-xl font-bold mb-4">Cart Summary</h3>
      <div className="mb-4 sm:text-sm ">
        <h4 className="font-semibold mb-2">Shipping Options</h4>
        <div>
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="shipping"
              value="express"
              checked={selectedShipping === "express"}
              onChange={() => setSelectedShipping("express")}
              disabled={!isExpressShippingEnabled}
              className="mr-2"
            />
            Express Shipping @ {formatNumber(expressShippingPrice)}
          </label>
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="shipping"
              value="standard"
              checked={selectedShipping === "standard"}
              onChange={() => setSelectedShipping("standard")}
              className="mr-2"
            />
            Standard Shipping @ {cleanedCurrencyCode}
            {formatNumber(standardShippingPrice)}
          </label>
        </div>
      </div>
      <div className="text-lg sm:text-md font-semibold">
        Total Price: {cleanedCurrencyCode}{formatNumber(totalPrice)}
      </div>
    </div>
  );
};

export default CartSummary;
