import React from "react";
import ReactDOM from "react-dom";
import { FaTimes } from "react-icons/fa";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-10/12 h-4/5 bg-gradient-to-br from-yellow-100 via-purple-100 to-purple-200 backdrop-blur-sm rounded-lg shadow-lg overflow-auto p-6">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
        {/* Blob in the middle */}
        <div
          className="fixed w-96 h-96 bg-purple-300 opacity-80 rounded-full filter blur-2xl"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        ></div>
        <div className="relative z-10">{children}</div>
      </div>
    </div>,
    document.getElementById("modal-root") // Render the modal into a div with id "modal-root"
  );
};

export default Modal;
