import React from "react";

const categories = [
  { title: "All", filter: [] },
  { title: "T-shirt", filter: ["tee"] },
  { title: "Sweatshirt", filter: ["sweatshirt"] },
  { title: "Joggers", filter: ["joggers"] },
];

const ShopFilters = ({
  selectedCategory,
  sortOption,
  handleCategoryChange,
  handleSortOptionChange,
}) => {
  return (
    <div className="flex justify-between mb-4 max-[777px]:justify-evenly">
      <div>
        <label className="mr-2 max-[650px]:pl-4">Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}
          className="p-2 border rounded"
        >
          {categories.map((category) => (
            <option key={category.title} value={category.title}>
              {category.title}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center">
        <label className="mr-2">Sort by:</label>
        <select
          value={sortOption}
          onChange={(e) => handleSortOptionChange(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="title">Title</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </div>
    </div>
  );
};

export default ShopFilters;
