import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Counter from "../Counter";
import { formatNumber } from "../printifyShop/functions";
import CartSummary from "../printifyShop/Cart/CartSummary";
import { Link } from "react-router-dom";

const SlidingCartContainer = ({
  cartItems,
  isOpen,
  onClose,
  updateQuantity,
  removeFromCart,
  currencySymbol,
  cleanedCurrentRate,
  totalCartPrice,
  authUser,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 w-80 bg-gradient-to-r from-yellow-200 via-purple-200 to-purple-300 h-full transform transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } shadow-lg z-50 overflow-y-auto`}
    >
      <div className="p-4">
        <button onClick={onClose} className="float-right">
          <AiOutlineClose size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4">Shopping Cart</h2>
        <div>
          {cartItems.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <div>
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="mb-4 bg-white p-2 rounded-md shadow-sm"
                >
                  <div className="flex items-center mb-2">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-16 h-auto object-cover mr-4"
                    />
                    <div>
                      <div className="font-bold">{item.name}</div>
                      <div className="text-sm text-gray-500">
                        Color: {item.color}
                      </div>
                      <div className="text-sm text-gray-500">
                        Size: {item.size}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold">Quantity:</span>
                    <Counter
                      quantity={item.quantity}
                      setQuantity={(newQuantity) =>
                        updateQuantity(item.id, item.variantId, newQuantity)
                      }
                    />
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold">Price:</span>
                    <span>
                      {currencySymbol}
                      {formatNumber(
                        item.price * item.quantity * cleanedCurrentRate
                      )}
                    </span>
                  </div>
                  <div className="text-right">
                    <button
                      onClick={() => removeFromCart(item.id, item.variantId)}
                      className="bg-red-700 text-white px-3 py-1 rounded hover:bg-red-900"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Cart Summary */}
        <div className="mt-8">
          {/* <h3 className="text-xl font-bold">
            Total: {currencySymbol}
            {formatNumber(totalCartPrice)}
          </h3> */}
          <CartSummary
            cartItems={cartItems}
            totalCartPrice={totalCartPrice}
            currentRate={cleanedCurrentRate}
            currencySymbol={currencySymbol}
            authUser={authUser}
          />
        </div>
        <div className="flex justify-end">
          <Link
            to="/shop/products/cart/checkout"
            className="bg-purple-600 hover:bg-purple-800 text-white px-4 py-2 rounded mt-4"
          >
            Checkout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SlidingCartContainer;
