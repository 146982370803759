import React from "react";

import DashboardSummary from "./DashboardSummary";
import AdminLayout from "./AdminLayout";

const AdminDashboard = () => {
  return (
    <AdminLayout>
      <DashboardSummary />
    </AdminLayout>
  );
};

export default AdminDashboard;
