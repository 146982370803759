import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import LoadingCover from "./LoadingCover";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import useProductsData from "../hooks/UseProductsData";
import useCartData from "../hooks/UseCartData";
import FloatingCartIcon from "./Cart/FloatingCart";
import SlidingCartContainer from "./Cart/SlidingCartContainer";
import MobileNavBar from "./Mobile/Navigation/MobileNavbar";

const Layout = ({ children }) => {
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");
  const [isLoading, setIsLoading] = useState(true);
  const { products } = useProductsData();

  const { cartItems, setCartItems, authUser } = useCartData();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const locationData = JSON.parse(sessionStorage.getItem("countryRate")) || {};
  const currentRate = locationData.rate || 1;
  const currencyCode = locationData.currencySymbol || "$";

  const toggleCart = () => setIsCartOpen((prev) => !prev);

  const totalCartPrice = useMemo(
    () =>
      cartItems.reduce(
        (total, item) => total + item.price * item.quantity * currentRate,
        0
      ),
    [cartItems, currentRate]
  );

  // Fetch products and cart items
  useEffect(() => {
    const loadPage = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    loadPage();
  }, []);

  const updateLocalStorage = (updatedCartItems) => {
    localStorage.setItem("cartData", JSON.stringify(updatedCartItems));
  };

  const updateFirestore = async (updatedCartItems) => {
    if (authUser) {
      try {
        const userCartDoc = doc(firestore, "users", authUser.email);
        await updateDoc(userCartDoc, { cart: updatedCartItems });
      } catch (error) {
        console.error("Error updating user cart data in Firestore: ", error);
      }
    }
  };

  const updateCartData = (updatedCartItems) => {
    setCartItems(updatedCartItems);
    updateLocalStorage(updatedCartItems);
    updateFirestore(updatedCartItems);
  };

  const updateQuantity = (productId, variantId, newQuantity) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === productId && item.variantId === variantId
        ? { ...item, quantity: newQuantity }
        : item
    );

    updateCartData(updatedCartItems);
  };

  const removeFromCart = (productId, variantId) => {
    const updatedCartItems = cartItems.filter(
      (item) => !(item.id === productId && item.variantId === variantId)
    );

    updateCartData(updatedCartItems);
  };

  return (
    <div className="overflow-x-hidden">
      <Navbar products={products} />
      <div className="container mx-auto mt-20">
        {/* <div className="mb-0">
          <Link to="/">Home</Link>
          {pathSegments.map((segment, index) => (
            <span key={index}>
              {" "}
              /{" "}
              <Link to={`/${pathSegments.slice(0, index + 1).join("/")}`}>
                {segment}
              </Link>
            </span>
          ))}
        </div> */}

        <div className="content">
          {children}
          {/* Floating Cart Icon */}
          <FloatingCartIcon itemCount={cartItems.length} onClick={toggleCart} />

          <SlidingCartContainer
            cartItems={cartItems}
            isOpen={isCartOpen}
            onClose={() => setIsCartOpen(false)}
            updateQuantity={updateQuantity}
            removeFromCart={removeFromCart}
            currencySymbol={currencyCode}
            cleanedCurrentRate={currentRate}
            totalCartPrice={totalCartPrice}
            authUser={authUser}
          />
        </div>
        {isLoading && <LoadingCover />}
      </div>
      <div className="hidden max-[600px]:block">
        <MobileNavBar />
      </div>
      <div id="modal-root"></div>

      <Footer />
    </div>
  );
};

export default Layout;
