import React, { useEffect, useState } from "react";
import { useAuth } from "../../adminComponents/AuthContext";
import EditContactInformation from "./EditContactInformation";
import UserReviews from "./UserReviews"; // Import UserReviews component
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Layout from "../../Layout";
import OrderHistory from "./OrderHistory";
import SignInComponent from "../Signin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import app from "../../../firebase";

const firestore = getFirestore(app);

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [contactInformationData, setContactInformationData] = useState({
    displayName: "",
    contactInformation: {
      address: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      phone: "",
    },
    orders: [],
  });
  const [editing, setEditing] = useState(false);
  const [orderInfo, setOrderInfo] = useState("");
  const [activeTab, setActiveTab] = useState("contact");
  const [authUser, setAuthUser] = useState(null); // Local state for auth user
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        const fetchUserProfile = async () => {
          try {
            const userDocRef = doc(firestore, "users", user.email);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              const fetchedUserData = userDoc.data();
              setContactInformationData({
                displayName: fetchedUserData.displayName || "",
                contactInformation: fetchedUserData.contactInformation || {
                  address: "",
                  country: "",
                  state: "",
                  city: "",
                  postalCode: "",
                  phone: "",
                },
                orders: fetchedUserData.orders || [],
              });

              const orderInformation = fetchedUserData.orders || [];

              if (orderInformation.length > 0) {
                const orderDocs = await Promise.all(
                  orderInformation.map(async (orderRef) => {
                    const orderDoc = await getDoc(orderRef);
                    return orderDoc.data();
                  })
                );

                setOrderInfo(orderDocs);
              } else {
                setOrderInfo("User hasn't placed any orders yet.");
              }
            }
          } catch (error) {
            console.error("Error fetching user profile:", error.message);
          }
        };

        fetchUserProfile();
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [editing]);

  const handleUpdateContactInformation = async (updatedContactInfo) => {
    try {
      const userDocRef = doc(firestore, "users", authUser.email);

      await updateDoc(userDocRef, {
        contactInformation: updatedContactInfo,
      });

      setEditing(false); // Turn off editing mode after successful update
    } catch (error) {
      console.error("Error updating contact information:", error.message);
    }
  };

  if (!authUser) {
    return (
      <div>
        <SignInComponent />
      </div>
    );
  }

  return (
    <Layout>
      <div className="min-h-screen">
        <div className="container mx-auto py-6 px-10">
          <div className="flex justify-around p-4 rounded-t-lg mb-6">
            <button
              className={`py-1 px-2 ${
                activeTab === "contact"
                  ? "bg-purple-500 text-white"
                  : "bg-white"
              } rounded shadow`}
              onClick={() => setActiveTab("contact")}
            >
              Contact Information
            </button>
            <button
              className={`py-1 px-2 ${
                activeTab === "orders" ? "bg-purple-500 text-white" : "bg-white"
              } rounded shadow`}
              onClick={() => setActiveTab("orders")}
            >
              Order Information
            </button>
            <button
              className={`py-1 px-2 ${
                activeTab === "reviews"
                  ? "bg-purple-500 text-white"
                  : "bg-white"
              } rounded shadow`}
              onClick={() => setActiveTab("reviews")}
            >
              Reviews
            </button>
          </div>

          {activeTab === "contact" && (
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              {editing ? (
                <EditContactInformation
                  currentUser={authUser}
                  setEditing={setEditing}
                  onUpdateContactInformation={handleUpdateContactInformation}
                />
              ) : (
                <>
                  <h3 className="text-xl font-semibold mb-4">
                    Contact Information
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">Display Name:</p>
                      <p>{contactInformationData?.displayName}</p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">Address:</p>
                      <p>
                        {contactInformationData?.contactInformation?.address}
                      </p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">Country:</p>
                      <p>
                        {contactInformationData?.contactInformation?.country}
                      </p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">State:</p>
                      <p>{contactInformationData?.contactInformation?.state}</p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">City/Town:</p>
                      <p>{contactInformationData?.contactInformation?.city}</p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">Postal Code:</p>
                      <p>
                        {contactInformationData?.contactInformation?.postalCode}
                      </p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="font-semibold">Phone:</p>
                      <p>{contactInformationData?.contactInformation?.phone}</p>
                    </div>
                  </div>
                  <button
                    onClick={() => setEditing(true)}
                    className="bg-purple-500 text-white px-3 py-1 rounded mt-4 hover:bg-purple-600"
                  >
                    Edit Contact Information
                  </button>
                </>
              )}
            </div>
          )}

          {activeTab === "orders" && <OrderHistory orderInfo={orderInfo} />}

          {activeTab === "reviews" && (
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <UserReviews />
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </Layout>
  );
};

export default UserProfile;
