import React, { useState } from "react";
import ContactInformation from "../../../Checkout/ContactInformation";
import ShippingAddress from "../../../Checkout/ShippingAddress";
import { firestore } from "../../../../firebase";
import { printifyBuyNow } from "../../../Checkout/CheckoutUtils";
import ProductSummary from "./ProductSummary";
import CustomerInformationCard from "./Cards/CustomerInformationCard";
import DeliveryInformationCard from "./Cards/DeliveryInformationCard";

const PrintifyCheckout = ({ product, onBack }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [fullName, setFullname] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState({
    displayName: "",
    email: "",
    contactInformation: {
      address: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      phone: "",
    },
  });

  // Validation functions
  const isContactInformationValid = () => {
    return fullName && email && phone;
  };

  const isShippingAddressValid = () => {
    return address && country && state && city && postalCode;
  };

  const handleNextStep = () => {
    if (step === 1 && !isContactInformationValid()) {
      alert("Please fill in all contact information fields.");
      return;
    }

    if (step === 2 && !isShippingAddressValid()) {
      alert("Please fill in all shipping address fields.");
      return;
    }

    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCheckout = () => {
    if (!isContactInformationValid() || !isShippingAddressValid()) {
      alert("Please make sure all fields are filled out correctly.");
      return;
    }

    // Ensure product structure is correct
    const formattedProduct = {
      id: product.id,
      variantId: product.variantId,
      quantity: product.quantity,
      title: product.title,
      price: product.price,
      image: product.image,
      is_printify_express: product.is_printify_express,
      is_economy_shipping: product.is_economy_shipping,
      send_shipping_notification: product.send_shipping_notification,
      shipping_method: product.shipping_method,
      shippingCost: product.shippingCost,
      shipping: product.shipping,
    };

    setIsLoading(true); // Set loading state to true before initiating checkout
    setStep(step + 1); // Move to the processing step

    // Perform the checkout process
    printifyBuyNow(
      {
        ...userInfo,
        displayName: fullName,
        email,
        contactInformation: {
          address,
          country,
          state,
          city,
          postalCode,
          phone,
        },
      },
      email,
      formattedProduct,
      setIsLoading,
    );
  };

  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 5);
  const formattedDeliveryDate = deliveryDate.toLocaleDateString();

  return (
    <div className="p-6 bg-inherit relative">
      <h2 className="text-2xl font-bold mb-4">Checkout</h2>
      <div className="py-4">
        <ProductSummary product={product} />
      </div>
      {step === 1 && (
        <div>
          <ContactInformation
            userInfo={userInfo}
            fullName={fullName}
            setFullname={setFullname}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
          />
          <div className="flex justify-between mt-4">
            <button
              onClick={onBack}
              className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Back
            </button>
            <button
              onClick={handleNextStep}
              className="bg-purple-600 hover:bg-purple-800 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div>
          <ShippingAddress
            userInfo={userInfo}
            address={address}
            setAddress={setAddress}
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
          />
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePreviousStep}
              className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Previous
            </button>
            <button
              onClick={handleNextStep}
              className="bg-purple-600 hover:bg-purple-800 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div>
          <p className="mt-4 mb-4 text-center text-lg font-bold">
            Review and Confirm your Order
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-2 border-solid border-gray-300 rounded">
            <CustomerInformationCard
              fullName={fullName}
              email={email}
              phone={phone}
              address={address}
              city={city}
              state={state}
              country={country}
              postalCode={postalCode}
            />
            <DeliveryInformationCard
              address={address}
              city={city}
              state={state}
              country={country}
              postalCode={postalCode}
              formattedDeliveryDate={formattedDeliveryDate}
            />
          </div>
          <div className="flex justify-between mt-6">
            <button
              onClick={handlePreviousStep}
              className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Previous
            </button>
            <button
              onClick={handleCheckout}
              className="bg-purple-600 hover:bg-purple-800 text-white px-6 py-3 rounded font-bold text-lg"
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-green-600 mb-4"></div>
          <p className="text-lg font-bold text-center">
            Payment processing... You will be redirected to the payment portal
            shortly. Please wait.
          </p>
        </div>
      )}
    </div>
  );
};

export default PrintifyCheckout;
