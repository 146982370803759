import React from "react";

const ProductSummary = ({ product }) => {
  return (
    <div className="flex">
      <img
        src={product.image}
        alt={product.title}
        className="w-32 h-32 object-cover rounded-md"
      />
      <div className="ml-4">
        <p>
          <strong>Product:</strong> {product.title}
        </p>
        <p>
          <strong>Price:</strong> ${product.price.toFixed(2)}
        </p>
        <p>
          <strong>Size:</strong> {product.size}
        </p>
        <p>
          <strong>Color:</strong> {product.color}
        </p>
        <p>
          <strong>Quantity:</strong> {product.quantity}
        </p>
      </div>
    </div>
  );
};

export default ProductSummary;
