// CustomerReviews.js

import React, { useMemo } from "react";
import StarRating from "../StarRating";
import CardRating from "../CardRating";

const CustomerReviews = ({ reviews, averageRating }) => {
  const calculateTotalRatings = (starRating) => {
    return reviews.filter((review) => review.rating === starRating).length;
  };

  const maxRatings = useMemo(() => {
    return Math.max(
      calculateTotalRatings(5),
      calculateTotalRatings(4),
      calculateTotalRatings(3),
      calculateTotalRatings(2),
      calculateTotalRatings(1)
    );
  }, [reviews]);

  const percentage5Stars = useMemo(
    () => (calculateTotalRatings(5) / maxRatings) * 100 || 0,
    [maxRatings, reviews]
  );
  const percentage4Stars = useMemo(
    () => (calculateTotalRatings(4) / maxRatings) * 100 || 0,
    [maxRatings, reviews]
  );
  const percentage3Stars = useMemo(
    () => (calculateTotalRatings(3) / maxRatings) * 100 || 0,
    [maxRatings, reviews]
  );
  const percentage2Stars = useMemo(
    () => (calculateTotalRatings(2) / maxRatings) * 100 || 0,
    [maxRatings, reviews]
  );
  const percentage1Stars = useMemo(
    () => (calculateTotalRatings(1) / maxRatings) * 100 || 0,
    [maxRatings, reviews]
  );

  return (
    <div className="bg-white mt-10 container  rounded-t-lg mx-auto my-2">
      <div className="flex items-center justify-between bg-white p-4 border-b border-gray-100">
        <h3 className="text-2xl font-bold">Customer Feedback</h3>
      </div>
      <div className="grid grid-cols-4 gap-8 m-4">
        {/* First column */}
        <div className="col-span-1 max-[777px]:hidden">
          {/* Star Rating Card */}
          <div className="bg-gray-100 p-4 rounded-md mb-4">
            <h4 className="text-lg text-center font-semibold mb-2">
              Verfied Ratings
            </h4>
            <div className="font-bold text-2xl">
              <CardRating rating={averageRating || 0} />{" "}
            </div>
          </div>

          {/* Total Ratings Card */}
          <div className="bg-white p-4 rounded-md">
            {/* <h4 className="text-lg font-semibold mb-2">Total Ratings</h4> */}

            {/* 5 Stars Rating */}
            <div className="flex items-center mb-2">
              <span className="w-16">
                5 <span className="text-yellow-500">&#9733;</span>:
              </span>
              <div className="flex-1 h-4 bg-gray-300 rounded-full">
                {/* Calculate the percentage of total ratings for 5 stars */}

                <div
                  className="h-full bg-yellow-500 rounded-full"
                  style={{ width: `${percentage5Stars}%` }}
                ></div>
              </div>
            </div>
            {/* 4 Stars Rating */}
            <div className="flex items-center mb-2">
              <span className="w-16">
                4 <span className="text-yellow-500">&#9733;</span>:
              </span>
              <div className="flex-1 h-4 bg-gray-300 rounded-full">
                {/* Calculate the percentage of total ratings for 4 stars */}

                <div
                  className="h-full bg-yellow-500 rounded-full"
                  style={{ width: `${percentage4Stars}%` }}
                ></div>
              </div>
            </div>
            {/* 3 Stars Rating */}
            <div className="flex items-center mb-2">
              <span className="w-16">
                3 <span className="text-yellow-500">&#9733;</span>:
              </span>
              <div className="flex-1 h-4 bg-gray-300 rounded-full">
                {/* Calculate the percentage of total ratings for 3 stars */}

                <div
                  className="h-full bg-yellow-500 rounded-full"
                  style={{ width: `${percentage3Stars}%` }}
                ></div>
              </div>
            </div>
            {/* 2 Stars Rating */}
            <div className="flex items-center mb-2">
              <span className="w-16">
                2 <span className="text-yellow-500">&#9733;</span>:
              </span>
              <div className="flex-1 h-4 bg-gray-300 rounded-full">
                {/* Calculate the percentage of total ratings for 2 stars */}

                <div
                  className="h-full bg-yellow-500 rounded-full"
                  style={{ width: `${percentage2Stars}%` }}
                ></div>
              </div>
            </div>
            {/* 1 Star Rating */}
            <div className="flex items-center mb-2">
              <span className="w-16">
                1 <span className="text-yellow-500">&#9733;</span>:
              </span>
              <div className="flex-1 h-4 bg-gray-300 rounded-full">
                {/* Calculate the percentage of total ratings for 1 star */}

                <div
                  className="h-full bg-yellow-500 rounded-full"
                  style={{ width: `${percentage1Stars}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* Second column */}
        <div className="col-span-3">
          <ul className="mt-5">
            {reviews ? (
              reviews.map((review, index) => (
                <li className="mb-6 pb-6 border-b border-gray-100" key={index}>
                  <p>User: {review.userName}</p>
                  <p>
                    <StarRating rating={review.rating || 0} />{" "}
                  </p>
                  <p>User Review: {review.reviewMessage}</p>
                  <p></p>
                  {/* Add more review details as needed */}
                </li>
              ))
            ) : (
              <p className="text-dark">
                This product has not yet been reviewed.
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
