import React, { useState, useEffect } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";

const EditContactInformation = ({
  currentUser,
  setEditing
}) => {
  const [formData, setFormData] = useState({
    displayName: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    phone: "",
  });

  const [userData, setUserData] = useState({
    displayName: "",
    contactInformation: {
      address: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      phone: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        if (currentUser && currentUser.email) {
          const userDocRef = doc(firestore, "users", currentUser.email);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const fetchedUserData = userDoc.data();
            setUserData({
              displayName: fetchedUserData.displayName || "",
              contactInformation: fetchedUserData.contactInformation || {},
            });

            setFormData({
              displayName: fetchedUserData.displayName || "",
              address: fetchedUserData.contactInformation?.address || "",
              country: fetchedUserData.contactInformation?.country || "",
              state: fetchedUserData.contactInformation?.state || "",
              city: fetchedUserData.contactInformation?.city || "",
              postalCode: fetchedUserData.contactInformation?.postalCode || "",
              phone: fetchedUserData.contactInformation?.phone || "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error.message);
      }
    };

    fetchCurrentUser();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (currentUser && !isSubmitting) {
        setIsSubmitting(true); // Set isSubmitting to true to disable the form submission button

        const isDataChanged = Object.keys(formData).some(
          (key) => formData[key] !== userData.contactInformation[key]
        );

        if (isDataChanged) {
          const userDocRef = doc(firestore, "users", currentUser.email);
          await updateDoc(userDocRef, {
            displayName: formData.displayName,
            contactInformation: {
              address: formData.address,
              country: formData.country,
              state: formData.state,
              city: formData.city,
              postalCode: formData.postalCode,
              phone: formData.phone,
            },
          });

          setUserData({
            displayName: formData.displayName,
            contactInformation: {
              address: formData.address,
              country: formData.country,
              state: formData.state,
              city: formData.city,
              postalCode: formData.postalCode,
              phone: formData.phone,
              cart: []
            },
          });
        }

        setEditing(false);
      }
    } catch (error) {
      console.error("Error updating contact information:", error.message);
    } finally {
      setIsSubmitting(false); // Reset isSubmitting to allow future submissions
    }
  };

  return (
    <div>
    <h3 className="text-xl font-semibold mb-4">Edit Contact Information</h3>
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="displayName" className="block font-semibold">Display Name:</label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            value={formData.displayName}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="address" className="block font-semibold">Home Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="country" className="block font-semibold">Country:</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="state" className="block font-semibold">State:</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="city" className="block font-semibold">City/Town:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="postalCode" className="block font-semibold">Postal Code:</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
        <div className="col-span-2 sm:col-span-1 mb-4">
          <label htmlFor="phone" className="block font-semibold">Phone Number:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="border rounded-md p-2 w-full"
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <button
          type="submit"
          className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={() => setEditing(false)}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
  
  );
};

export default EditContactInformation;
