import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { useAuth } from "../adminComponents/AuthContext";
import { firestore } from "../../firebase";
import { useNavigate } from "react-router-dom";
import ContactInformation from "./ContactInformation";
import ShippingAddress from "./ShippingAddress";
import OrderSummary from "./OrderSummary";
import {
  fetchCartData,
  handlePrintifyPayment,
  resetCart,
} from "./CheckoutUtils";
import { getDoc, doc } from "firebase/firestore";

const Checkout = ({ userProfile, brandName }) => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [userInfo, setUserInfo] = useState({
    displayName: "",
    email: "",
    contactInformation: {
      address: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      phone: "",
    },
  });
  const [fullName, setFullname] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [printifyProducts, setPrintifyProducts] = useState([]);
  const navigate = useNavigate();

  const locationData = JSON.parse(sessionStorage.getItem("countryRate"));
  const currencyCode = "$";
  const countryCode = locationData?.countryCode || "USD";
  const currentRate = locationData?.rate || 1;
  const cleanedCurrentRate =
    currentRate === undefined ? 1 : parseInt(currentRate.toFixed(0));
  // const cleaedCurrencyCode = currencyCode === undefined ? "$" : currencyCode;

  useEffect(() => {
    fetchCartData(currentUser, setCartItems, setPrintifyProducts, firestore);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      async function fetchLoggedInUserData() {
        const userDocRef = doc(firestore, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const fetchedUserData = userDoc.data();
          setUserInfo({
            displayName: fetchedUserData.displayName || "",
            email: currentUser.email || "",
            contactInformation: fetchedUserData.contactInformation || {
              address: "",
              country: "",
              state: "",
              city: "",
              postalCode: "",
              phone: "",
            },
          });
        }
      }
      fetchLoggedInUserData();
    }
  }, [currentUser, userProfile]);

  const calculateTotalPrice = () => {
    return (
      cartItems.reduce(
        (total, item) =>
          total + item.quantity * item.price * cleanedCurrentRate,
        0
      ) + (cartItems[0]?.shippingCost || 0)
    );
  };

  const formCheck = () => {
    const requiredFields = [
      email,
      fullName,
      address,
      phone,
      country,
      state,
      city,
      postalCode,
    ];

    if (requiredFields.every((field) => field)) {
      return (
        <button
          type="button"
          className={`bg-blue-700 text-white py-2 px-4 rounded-md ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() =>
            handlePrintifyPayment(
              {
                ...userInfo,
                displayName: fullName,
                email,
                contactInformation: {
                  address,
                  country,
                  state,
                  city,
                  postalCode,
                  phone,
                },
              },
              email, // Ensure correct parameter order
              printifyProducts,
              setIsLoading, // Pass the setIsLoading function here
              calculateTotalPrice
            )
          }
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                ></path>
              </svg>
              Processing Payment...
            </>
          ) : (
            " Pay with Stripe"
          )}
        </button>
      );
    }
  };

  return (
    <Layout products={cartItems}>
      <h2 className="text-3xl font-bold text-center py-10">Checkout</h2>

      <div className="container mx-auto mb-10 flex flex-col items-start md:flex-row px-16 max-[1024px]:px-8 max-[777px]:px-4">
        <div className="md:w-1/2 pr-0 md:pr-8">
          <form>
            <ContactInformation
              userInfo={userInfo}
              fullName={fullName}
              setFullname={setFullname}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
            />
            <ShippingAddress
              userInfo={userInfo}
              address={address}
              setAddress={setAddress}
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
            />
          </form>
        </div>

        <div className="md:w-1/2 mt-8 md:mt-0">
          <OrderSummary
            cartItems={cartItems}
            currencyCode={currencyCode}
            currentRate={currentRate}
          />

          <div className="mt-8">
            {countryCode === "NGN" ? formCheck() : formCheck()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
