import React, { useState } from "react";
import Layout from "./Layout";
import { useAuth } from "./adminComponents/AuthContext";
import { firestore} from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const TrackingOrder = () => {
  const { currentUser } = useAuth();
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);

  const handleFetchOrder = async () => {
    try {
      // If the user is authenticated, request orderId and email
      if (currentUser) {
        const userDocRef = doc(firestore, "users", currentUser.email);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const fetchedUserData = userDoc.data();

          // Check if the order exists in the user's orders array
          const userOrder = fetchedUserData.orders.find(
            (orderRef) => orderRef.id === orderId
          );

          if (userOrder) {
            const orderDoc = await getDoc(userOrder);
            setOrderData(orderDoc.data());
          } else {
            setError("Order not found for the provided ID");
          }
        } else {
          setError("User data not found");
        }
      } else {
        // If the user is not authenticated, request only orderId and name
        const orderDocRef = doc(firestore, "orders", orderId);
        const orderDoc = await getDoc(orderDocRef);

        if (orderDoc.exists()) {
          setOrderData(orderDoc.data());
        } else {
          setError("Order not found for the provided ID");
        }
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setError("An error occurred while fetching order data");
    }
  };

  return (
    <Layout>
      <div className="container mx-auto my-16">
        <h2 className="text-3xl font-bold mb-8">Track Your Order</h2>

        <div className="mb-4">
          <label htmlFor="orderId" className="block text-sm font-medium text-gray-700">
            Order ID:
          </label>
          <input
            type="text"
            id="orderId"
            name="orderId"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <button
          className="bg-blue-500 text-white p-2 rounded-md cursor-pointer"
          onClick={handleFetchOrder}
        >
          Track Order
        </button>

        {error && <p className="text-red-500 mt-4">{error}</p>}

        {orderData && (
          <div className="mt-8">
            <h3 className="text-xl font-semibold mb-2">Order Details:</h3>
            <pre>{JSON.stringify(orderData, null, 2)}</pre>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TrackingOrder;
