import React from "react";
import Logo from "../assets/favicon.ico"; // Adjust the path to your logo

const HomeIcon = ({ size }) => {
  return (
    <div
      className="bg-white rounded-full py-2 flex justify-center items-center"
      style={{ width: size, height: size, marginTop: "-15px" }} // Adjust size and marginTop as needed
    >
      <img src={Logo} alt="Home" className="w-3/4 h-3/4 object-contain" />
    </div>
  );
};

export default HomeIcon;
