import React from 'react';

const ShippingAddress = ({ userInfo, address, setAddress, country, setCountry, state, setState, city, setCity, postalCode, setPostalCode }) => {
  return (
    <div className="border p-4 rounded-md">
      <h3 className="text-xl font-semibold mb-4">Shipping Address</h3>
      {userInfo ? (
        <>
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
              Address
            </label>
            <textarea
              id="address"
              name="address"
              placeholder={userInfo.contactInformation.address}
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full resize-none"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              placeholder={userInfo.contactInformation.country}
              value={country || ""}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-medium text-gray-700">
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              placeholder={userInfo.contactInformation.state}
              value={state || ""}
              onChange={(e) => setState(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              City/Town
            </label>
            <input
              type="text"
              id="city"
              name="city"
              placeholder={userInfo.contactInformation.city}
              value={city || ""}
              onChange={(e) => setCity(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
              Postal Code
            </label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              placeholder={userInfo.contactInformation.postalCode}
              value={postalCode || ""}
              onChange={(e) => setPostalCode(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
        </>
      ) : (
        <>
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
              Address
            </label>
            <textarea
              id="address"
              name="address"
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full resize-none"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={country || ""}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="state" className="block text-sm font-medium text-gray-700">
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={state || ""}
              onChange={(e) => setState(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              City/Town
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={city || ""}
              onChange={(e) => setCity(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
              Postal Code
            </label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={postalCode || ""}
              onChange={(e) => setPostalCode(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              required
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ShippingAddress;
