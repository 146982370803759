import React from "react";

const SkeletonProductCard = () => {
  return (
    <div className="relative mx-2 my-2 flex flex-col h-full">
      <div className="group p-2 hover:shadow-md rounded cursor-pointer">
        <div className="relative w-20 h-20 mb-2 bg-gray-200 animate-pulse"></div>
        <div className="flex-grow flex flex-col mt-1">
          <div className="h-3 bg-gray-200 mb-2 animate-pulse w-3/4"></div>
          <div className="h-3 bg-gray-200 animate-pulse w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonProductCard;
