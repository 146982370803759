import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updateDoc, doc } from "firebase/firestore";
import app from "../firebase";
import { firestore } from "../firebase";

const PostCard = ({ post }) => {
  const [viewCount, setViewCount] = useState(post.views);
  const [showFullContent, setShowFullContent] = useState(false);

  // Handle click on "Read more" button
  const handleReadMore = async () => {
    // Update the view count
    setViewCount((prevCount) => prevCount + 1);

    // Save the updated view count to Firestore

    const postDocRef = doc(firestore, "blog", post.title.replace(/\s+/g, "-"));
    await updateDoc(postDocRef, { views: viewCount + 1 });

    // Show the full content
    setShowFullContent(true);
  };

  // Extracting the first 200 characters of the content
  const truncatedContent = showFullContent
    ? post.content
    : post.content.slice(0, 300);
  const remainingContent = post.content.slice(300);

  return (
    <div className="bg-white p-4 rounded-md shadow-md mb-4">
      {/* Author, Publish Date, and Category */}
      <div className="flex items-center mb-2">
        <span className="text-gray-500 mr-2">
          By <strong>{post.author}</strong>
        </span>
        <div className="text-gray-500 mr-2">
          Published on {post.publishDate}
        </div>
        <span className="text-gray-500">Category: {post.category}</span>
      </div>

      {/* Title */}
      <h2 className="text-3xl font-bold mb-2">{post.title}</h2>

      {/* Subtitle */}
      {post.subtitle && <p className="text-gray-700 mb-2">{post.subtitle}</p>}

      {/* Image */}
      {post.imageUrl && (
        <Link to={`/blog/${post.title.replace(/\s+/g, "-")}`}>
          <img
            src={post.imageUrl}
            alt={post.title}
            className="w-full h-auto mb-4 rounded-md"
            onClick={handleReadMore}
          />
        </Link>
      )}

      {/* Display the truncated content */}
      <p className="text-gray-700 mb-4">
        {truncatedContent}
        {remainingContent.length > 0 && !showFullContent && (
          <span>
            ...{" "}
            <Link to={`/blog/${post.title.replace(/\s+/g, "-")}`}>
              <button
                onClick={handleReadMore}
                className="text-blue-500 hover:underline cursor-pointer"
              >
                Read more
              </button>
            </Link>
          </span>
        )}
      </p>

      {/* Views Counter */}
      <div className="flex items-center">
        <span className="text-gray-500 mr-2">Views: {viewCount}</span>
      </div>
    </div>
  );
};

export default PostCard;
