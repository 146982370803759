import React, { useEffect, useState } from "react";
import NoResultsSVG from "./NoResultsSVG"; // Replace this with your own SVG or animation component
import { getDownloadURL, ref } from "@firebase/storage";
import { storage } from "../firebase";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import LoadingCover from "./LoadingCover";
import ProductCard from "./printifyShop/Products/ProductCard/ProductCard";
import Layout from "./Layout";

const QueryResult = () => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchImagesForProducts = async () => {
      try {
        const storedResults =
          JSON.parse(sessionStorage.getItem("filteredResults")) || [];

        setResults(storedResults);
      } catch (error) {
        console.error("Error fetching products:", error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImagesForProducts();
  }, []);

  if (isLoading) {
    return <LoadingCover />;
  }

  return (
    <Layout>
      <div className="container mx-auto my-16 pt-4 min-h-screen">
        <p className="text-2xl font-bold mb-8 text-center">Search Results</p>

        {results.length > 0 ? (
          <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-8">
            {results.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center mt-8">
            <NoResultsSVG />
            <p className="text-lg font-semibold text-gray-600 mt-4">
              No products found.
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default QueryResult;
