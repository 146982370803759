import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { firestore, auth } from "../firebase";

const useCartData = () => {
  const [cartItems, setCartItems] = useState([]);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
      fetchCartData(user);
    });

    return () => unsubscribe();
  }, []);

  const fetchCartData = async (user) => {
    if (user) {
      try {
        const userCartDoc = doc(firestore, "users", user.email);
        const userCartData = await getDoc(userCartDoc);

        if (userCartData.exists()) {
          setCartItems(userCartData.data().cart || []);
        } else {
          setCartItems([]); // Set empty cart if no data exists in Firestore
        }
      } catch (error) {
        console.error("Error fetching user cart data: ", error);
        setCartItems([]); // Fallback to empty cart on error
      }
    } else {
      const storedCartData = JSON.parse(localStorage.getItem("cartData")) || [];
      setCartItems(storedCartData);
    }
  };

  return { cartItems, authUser, setCartItems };
};

export default useCartData;
