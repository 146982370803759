import React, { useState, useEffect } from "react";
import { useAuth } from "../../adminComponents/AuthContext";
import styles from "./UserReviews.module.css"; // Import CSS module
import {
  collection,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
  writeBatch,
  arrayUnion,
} from "firebase/firestore"; // Adjust the imports
import { firestore } from "../../../firebase";

const UserReviews = ({ userName }) => {
  const { currentUser } = useAuth();
  const [userOrders, setUserOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [rating, setRating] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewMessage, setReviewMessage] = useState("");
  const [orderDataId, setOrderDataId] = useState("");

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const userDocRef = doc(firestore, "users", currentUser.email);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const usersOrders = userData.orders;

          if (usersOrders.length > 0) {
            // console.log(
            //   "userOrders id: ",
            //   usersOrders.map((orders) => orders.cartItems)
            // );
            // console.log("User Orders:", usersOrders);
            setUserOrders(usersOrders);
            setIsLoading(false); // Move setIsLoading(false) here
          } else {
            setUserOrders("User hasn't placed any orders yet.");
            setIsLoading(false); // Move setIsLoading(false) here as well
          }
        }
      } catch (error) {
        console.error("Error fetching user orders:", error);
        setIsLoading(false); // Set isLoading to false in case of an error
      }
    };

    fetchUserOrders();
  }, [currentUser]);

  const fetchProductDetails = async (orderId) => {
    try {
      const orderDocRef = doc(firestore, "orders", orderId);
      const orderDocSnapshot = await getDoc(orderDocRef);

      if (orderDocSnapshot.exists()) {
        const orderData = orderDocSnapshot.data();
        // console.log("Order Data:", orderData);

        // Use find() instead of map() to get the selected product details
        const selectedProductDetails = orderData.cartItems.map((data) => data);

        setSelectedProduct(selectedProductDetails);
        // .find(
        //   (cartItem) => cartItem
        // );

        // console.log("Selected Product Details:", selectedProductDetails);

        // You can use selectedProductDetails for further processing
        return selectedProductDetails;
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleOrderSelection = (orderId) => {
    fetchProductDetails(orderId);
    setOrderDataId(orderId);
    const selectedOrder = userOrders.find((order) => order.orderId === orderId);
    // console.log("Selected Order", selectedOrder);
    setSelectedOrder(selectedOrder);
    setSelectedProduct(""); // Reset selectedProduct when order changes
  };

  const handleProductSelection = (productName) => {
    // console.log("Product Selected:", productName);
    // Find the selected product from the array of products based on the productId
    const selectedProducts = selectedProduct.find(
      (item) => item.productName === productName
    );

    // console.log("THis is selected: ", selectedProducts);

    // Update setSelectedProduct with the selected product
    setSelectedProduct(selectedProducts ? [selectedProducts] : []);
  };

  const handleReviewSubmission = async () => {
    try {
      const reviewId = "RID" + currentUser.email.length + Date.now().toString();
      const batch = writeBatch(firestore);
      const setProduct = selectedProduct[0].productName

      // Append reviewData to selectedProduct[0].reviews array
      const reviewData = {
        product: setProduct,
        userId: currentUser.email,
        userName: userName,
        reviewDate: new Date(), // Use JavaScript Date for review date
        rating,
        reviewMessage,
      };
      if (!selectedProduct[0].reviews) {
        selectedProduct[0].reviews = []; // Initialize reviews array if it doesn't exist
      }
      selectedProduct[0].reviews.push(reviewData);

      // Add batch operations
      const reviewDocRef = doc(firestore, "reviews", reviewId);
      batch.set(reviewDocRef, reviewData);

      const userDocRef = doc(firestore, "users", currentUser.email);
      batch.update(userDocRef, {
        reviews: arrayUnion(reviewData), // Append reviewData to existing reviews array
      });

      const orderDocRef = doc(firestore, "orders", orderDataId);
      batch.update(orderDocRef, {
        reviews: arrayUnion(reviewData), // Append reviewData to existing reviews array
      });

      // Commit the batch
      await batch.commit();

      // Clear form and state after successful submission
      setSelectedOrder(null);
      setSelectedProduct("");
      setRating(5);
      setReviewMessage("");

      // console.log("Review submitted successfully!");
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <>
          <h2>Reviews</h2>
          {userOrders.length > 0 ? (
            <div>
              <label className={styles.label}>Select an order to review:</label>
              <select
                className={styles.select}
                value={selectedOrder ? selectedOrder.orderId : ""}
                onChange={(e) => handleOrderSelection(e.target.value)}
              >
                <option value="" disabled>
                  Select an order
                </option>
                {userOrders.map((order) => (
                  <option key={order.id} value={order.id}>
                    {order.id}
                  </option>
                ))}
              </select>
              {selectedProduct && (
                <React.Fragment>
                  <label className={styles.label}>
                    Select a product to review:
                  </label>
                  <select
                    className={styles.select}
                    value={selectedProduct} // Assuming selectedProduct is the selected product
                    onChange={(e) => handleProductSelection(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a product
                    </option>
                    {selectedProduct.map((product, index) => (
                      <option key={index} value={product.productName}>
                        {product.productName}
                      </option>
                    ))}
                  </select>
                </React.Fragment>
              )}

              <label className={styles.label}>Rate the product (1-5):</label>
              <input
                className={styles.input}
                type="number"
                min="1"
                max="5"
                value={rating}
                onChange={(e) => setRating(parseInt(e.target.value, 6))}
              />

              <label className={styles.label}>Write your review:</label>
              <textarea
                className={styles.textarea}
                value={reviewMessage}
                onChange={(e) => setReviewMessage(e.target.value)}
              />

              <button
                className={styles.button}
                onClick={handleReviewSubmission}
              >
                Submit Review
              </button>
            </div>
          ) : (
            <p>
              No orders placed yet. Please place an order to leave a review.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default UserReviews;
