import React, { useState } from "react";
import useWindowSize from "../../../hooks/UseWindowSize";

const OrderHistory = ({ orderInfo }) => {
  const { width } = useWindowSize();
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPageDesktop = 5;
  const ordersPerPageMobile = 3;
  const [isAnimating, setIsAnimating] = useState(false);

  const handleExpandOrder = (index) => {
    setExpandedOrder(expandedOrder === index ? null : index);
  };

  const handleNextPage = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setIsAnimating(false);
      }, 300); // Set a timeout equal to the animation duration
    }
  };

  const handlePreviousPage = () => {
    if (!isAnimating && currentPage > 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage - 1);
        setIsAnimating(false);
      }, 300); // Set a timeout equal to the animation duration
    }
  };

  // Determine the orders to display based on the current page
  const paginatedOrders = orderInfo.slice(
    (currentPage - 1) * ordersPerPageDesktop,
    currentPage * ordersPerPageDesktop
  );

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h3 className="text-xl font-bold mb-4">Order Information</h3>

      <div
        className={`transition-all duration-300 ease-in-out ${isAnimating ? "opacity-50" : "opacity-100"}`}
      >
        <div className="hidden md:block">
          {/* Desktop View: Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 text-center">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Order ID</th>
                  <th className="py-2 px-4 border-b">Order Reference</th>
                  <th className="py-2 px-4 border-b">Order Status</th>
                  <th className="py-2 px-4 border-b">Transaction ID</th>
                  <th className="py-2 px-4 border-b">Date</th>
                  <th className="py-2 px-4 border-b">Customer Name</th>
                  <th className="py-2 px-4 border-b">Payment Method</th>
                  <th className="py-2 px-4 border-b">Cart</th>
                </tr>
              </thead>
              <tbody>
                {paginatedOrders.length > 0 ? (
                  paginatedOrders.map((order, index) => (
                    <React.Fragment key={index}>
                      <tr className={index % 2 === 0 ? "bg-gray-100" : ""}>
                        <td className="py-2 px-4 border-b">{order.orderId}</td>
                        <td className="py-2 px-4 border-b">
                          {order.orderReference}
                        </td>
                        <td className="py-2 px-4 border-b">{order.status}</td>
                        <td className="py-2 px-4 border-b">
                          {order.transactionId}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {order.date && order.date.toDate().toLocaleString()}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {order.customerName}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {order.paymentMethod}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {order.cartItems?.length > 0 && (
                            <div className="text-sm text-gray-700">
                              {order.cartItems.map((item, i) => (
                                <div key={i}>
                                  <p>{item.productName}</p>
                                  <p>
                                    {item.productQuantity} x $
                                    {item.productPrice}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="py-4">
                      You haven't made any orders yet.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Mobile View: Cards */}
        <div className="md:hidden">
          {paginatedOrders.length > 0 ? (
            paginatedOrders.map((order, index) => (
              <div
                key={index}
                className="mb-4 p-4 bg-white shadow-md rounded-lg cursor-pointer transition-all duration-300 ease-in-out"
                onClick={() => handleExpandOrder(index)}
              >
                <div className="flex justify-between">
                  <p className="text-sm font-semibold">
                    Order ID: {order.orderId}
                  </p>
                  <p className="text-sm font-semibold">{order.status}</p>
                </div>
                <p className="text-sm text-gray-700">
                  {order.date && order.date.toDate().toLocaleString()}
                </p>
                {expandedOrder === index && (
                  <div className="mt-2 transition-all duration-300 ease-in-out">
                    <p className="text-sm font-semibold">Order Reference:</p>
                    <p className="text-sm text-gray-700">
                      {order.orderReference}
                    </p>
                    <p className="text-sm font-semibold">Transaction ID:</p>
                    <p className="text-sm text-gray-700">
                      {order.transactionId}
                    </p>
                    <p className="text-sm font-semibold">Customer Name:</p>
                    <p className="text-sm text-gray-700">
                      {order.customerName}
                    </p>
                    <p className="text-sm font-semibold">Payment Method:</p>
                    <p className="text-sm text-gray-700">
                      {order.paymentMethod}
                    </p>

                    {order.cartItems?.length > 0 && (
                      <div className="mt-2">
                        <p className="text-sm font-semibold">Cart Items:</p>
                        {order.cartItems.map((item, i) => (
                          <div key={i} className="text-sm text-gray-700">
                            <p>{item.productName}</p>
                            <p>
                              {item.productQuantity} x ${item.productPrice}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-sm">
              You haven't made any orders yet.
            </p>
          )}
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-center space-x-5 mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-purple-500 text-white px-3 py-1 rounded-l hover:bg-purple-600 disabled:opacity-50 transition-all duration-300 ease-in-out"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={
            paginatedOrders.length <
            (width < 600 ? ordersPerPageMobile : ordersPerPageDesktop)
          }
          className="bg-purple-500 text-white px-3 py-1 rounded-r hover:bg-purple-600 disabled:opacity-50 transition-all duration-300 ease-in-out"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default OrderHistory;
