// TopNavbar.js
import React from "react";
import { Link } from "react-router-dom";
import OgasLogo from "../../assets/Ogas_Logo.webp";
import { useAuth } from "./AuthContext";

const TopNavbar = () => {
  const { currentUser, signOut } = useAuth();

  return (
    <nav className="bg-yellow-800 h-screen flex flex-col justify-between fixed top-0 left-0 w-1/8">
      <div>
        {/* Logo */}
        <div className="text-white text-2xl font-bold mb-4">
          <img src={OgasLogo} width={100} height={50} alt="Ogascounty" />
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col space-y-4">
          <Link to="/" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Homepage
          </Link>
          <Link to="/shop" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Store
          </Link>
          <Link to="/admin-dashboard/product-upload" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Product Form
          </Link>
          <Link to="/admin-dashboard/products" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Products
          </Link>
          <Link to="/admin-dashboard/blog-post-upload" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Upload Blog Post
          </Link>
          <Link to="/admin-dashboard/users" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Users
          </Link>
          <Link to="/admin-dashboard/orders" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Orders
          </Link>
        </div>
      </div>

      {/* Authentication Links */}
      <div>
        <Link to="/profile" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
          Account
        </Link>
        {currentUser ? (
          // User is authenticated, show "Sign Out" button
          <button onClick={signOut} className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Sign Out
          </button>
        ) : (
          // User is not authenticated, show "Sign In" link
          <Link to="/signin" className="text-white hover:bg-white hover:text-yellow-800 py-2 px-4 rounded-md transition duration-300">
            Sign In
          </Link>
        )}
      </div>
    </nav>
  );
};

export default TopNavbar;
