import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteField,
  arrayUnion,
} from "firebase/firestore";
import { firestore } from "../../firebase";

// Handle the checkout process for "Buy Now" with Printify
export const printifyBuyNow = async (
  userInfo,
  email,
  product, // single product for "Buy Now"
  setIsLoading
) => {
  setIsLoading(true);
  const userMail = email || userInfo.email;
  const emailUsername = userMail.split("@")[0];

  const currentDate = new Date().toISOString().replace(/[:.]/g, "");
  const uniqueDocumentId = `cs-${emailUsername}-${currentDate}-${Math.floor(
    Math.random() * 10000
  )}`;

  const generateLabel = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hour = now.getHours();
    const minute = now.getMinutes();

    return `${dayOfWeek}${hour.toString().padStart(2, "0")}${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const printifyProductData = {
    externalId: uniqueDocumentId,
    label: generateLabel(),
    line_items: [
      {
        product_id: product.id, // Assuming product.id is correct
        variant_id: product.variantId, // Assuming product.variantId is correct
        quantity: product.quantity,
      },
    ],
    address_to: {
      first_name: userInfo.displayName.split(" ")[0] || "",
      last_name: userInfo.displayName.split(" ")[1] || "",
      email: userMail,
      address1: userInfo.contactInformation.address || "",
      country: userInfo.contactInformation.country || "",
      region: userInfo.contactInformation.state || "",
      city: userInfo.contactInformation.city || "",
      zip: userInfo.contactInformation.postalCode || "",
      phone: userInfo.contactInformation.phone || "",
    },
    currency: "USD",
    is_printify_express: product.is_printify_express || false,
    is_economy_shipping: product.is_economy_shipping || false,
    send_shipping_notification: product.send_shipping_notification || true,
    shipping_method: product.shipping_method || 1,
    paymentStatus: "processing",
    products: [
      {
        productName: product.title, // Changed from product.name to product.title
        productQuantity: product.quantity,
        amount: product.price,
        image: product.image,
      },
    ],
    email: userMail,
    shippingCost: product.shippingCost || 0, // Added default value
    shipping: product.shipping || 0, // Added default value
    name: userInfo.displayName,
    amount: product.price * product.quantity,
  };

  await fetch(
    `https://tjevzx4faow3k2w3xozetileuq0foqku.lambda-url.us-east-1.on.aws/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(printifyProductData),
    }
  ).then(async (res) => {
    if (res.ok) {
      const data = await res.json();
      setIsLoading(false);

      await addOrUpdateCheckoutSession(data, printifyProductData);

      window.open(data.url, "_blank");
    } else {
      console.error("Error:", res.statusText);
    }
  });
};

// Fetch cart data either from Firestore for authenticated users or from localStorage for unauthenticated users
export const fetchCartData = async (
  currentUser,
  setCartItems,
  setPrintifyProducts
) => {
  if (currentUser) {
    try {
      const userDocRef = doc(firestore, "users", currentUser.email);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const fetchedUserData = userDoc.data();
        const userCartItems = fetchedUserData.cart || [];

        const printifyCheckout = userCartItems.filter(
          (item) => item.shipping_method === 1
        );

        if (printifyCheckout.length > 0) {
          setPrintifyProducts(printifyCheckout);
        }
        setCartItems(userCartItems);
      }
    } catch (error) {
      console.error("Error fetching user cart data from Firestore: ", error);
    }
  } else {
    const storedCartData = JSON.parse(localStorage.getItem("cartData")) || [];

    const printifyCheckout = storedCartData.filter(
      (item) => item.shipping_method === 1
    );

    if (printifyCheckout.length > 0) {
      setPrintifyProducts(printifyCheckout);
    }
    setCartItems(storedCartData);
  }
};

// Handle the checkout process with Printify
export const handlePrintifyPayment = async (
  userInfo,
  email,
  printifyProducts,
  setIsLoading,
  calculateTotalPrice
) => {
  setIsLoading(true); // Ensure this line is executed correctly

  const userMail = email || userInfo.email;
  const emailUsername = userMail.split("@")[0];

  const currentDate = new Date().toISOString().replace(/[:.]/g, "");
  const uniqueDocumentId = `cs-${emailUsername}-${currentDate}-${Math.floor(
    Math.random() * 10000
  )}`;

  const generateLabel = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hour = now.getHours();
    const minute = now.getMinutes();

    return `${dayOfWeek}${hour.toString().padStart(2, "0")}${minute
      .toString()
      .padStart(2, "0")}`;
  };

  const printifyProductData = {
    externalId: uniqueDocumentId,
    label: generateLabel(),
    line_items: printifyProducts.flatMap((product) =>
      product.line_items.map((item) => ({
        product_id: item.id,
        variant_id: item.variantId,
        quantity: item.quantity,
      }))
    ),
    address_to: {
      first_name: userInfo.displayName.split(" ")[0] || "",
      last_name: userInfo.displayName.split(" ")[1] || "",
      email: userMail,
      address1: userInfo.contactInformation.address || "",
      country: userInfo.contactInformation.country || "",
      region: userInfo.contactInformation.state || "",
      city: userInfo.contactInformation.city || "",
      zip: userInfo.contactInformation.postalCode || "",
      phone: userInfo.contactInformation.phone || "",
    },
    currency: "USD",
    is_printify_express:
      printifyProducts.length > 0
        ? printifyProducts[0].is_printify_express
        : false,
    is_economy_shipping:
      printifyProducts.length > 0
        ? printifyProducts[0].is_economy_shipping
        : false,
    send_shipping_notification:
      printifyProducts.length > 0
        ? printifyProducts[0].send_shipping_notification
        : true,
    shipping_method:
      printifyProducts.length > 0 ? printifyProducts[0].shipping_method : 1,
    paymentStatus: "processing",
    products: printifyProducts.map((item) => ({
      productName: item.name,
      productQuantity: item.quantity,
      amount: item.price,
      image: item.image,
    })),
    email: userMail,
    shippingCost: printifyProducts[0]?.shippingCost,
    shipping: printifyProducts[0]?.shipping,
    name: userInfo.displayName,
    amount: calculateTotalPrice(),
  };

  await fetch(
    `https://tjevzx4faow3k2w3xozetileuq0foqku.lambda-url.us-east-1.on.aws/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(printifyProductData),
    }
  ).then(async (res) => {
    if (res.ok) {
      const data = await res.json();
      setIsLoading(false);

      await addOrUpdateCheckoutSession(data, printifyProductData);

      window.open(data.url, "_blank");
    } else {
      console.error("Error:", res.statusText);
    }
  });
};

// Add or update the checkout session in Firestore
export const addOrUpdateCheckoutSession = async (data, printifyProductData) => {
  const checkoutSessionRef = doc(
    firestore,
    "checkout-sessions",
    data.customer_email
  );

  try {
    const docSnapshot = await getDoc(checkoutSessionRef);

    if (docSnapshot.exists()) {
      await updateDoc(checkoutSessionRef, {
        data: arrayUnion(printifyProductData),
      });
      console.log("Checkout session updated successfully!");
    } else {
      await setDoc(checkoutSessionRef, { data: [printifyProductData] });
      console.log("New checkout session created successfully!");
    }
  } catch (error) {
    console.error("Error adding or updating checkout session:", error);
  }
};

// Reset the cart after successful checkout
export const resetCart = (currentUser, setCartItems) => {
  localStorage.removeItem("cartData");
  setCartItems([]);

  if (currentUser) {
    const userCartDoc = doc(firestore, "users", currentUser.email);

    updateDoc(userCartDoc, {
      cart: deleteField(),
    })
      .then(() => {
        console.log("Cart data removed from Firestore successfully");
      })
      .catch((error) => {
        console.error("Error removing cart data from Firestore: ", error);
      });
  }
};
