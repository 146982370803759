import React from "react";

const UserInfo = ({ user, closeUserInfo }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">User Information - {user.displayName}</h2>
      <table className="min-w-full bg-white border border-gray-300 text-center">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Display Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            {/* Add more fields as needed */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2 px-4 border-b">{user.displayName}</td>
            <td className="py-2 px-4 border-b">{user.email}</td>
            {/* Add more cells as needed */}
          </tr>
        </tbody>
      </table>

      {user.contactInformation && (
        <div className="mt-4">
          <h3 className="text-xl font-bold mb-2">Contact Information</h3>
          <table className="min-w-full bg-white border border-gray-300 text-left">
            <tbody>
              <tr>
                <td className="py-2 px-4 border-b font-bold">Address:</td>
                <td className="py-2 px-4 border-b">{user.contactInformation.address}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b font-bold">Country:</td>
                <td className="py-2 px-4 border-b">{user.contactInformation.country}</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      )}

      <button
        onClick={closeUserInfo}
        className="bg-blue-500 text-white px-3 py-1 rounded-full hover:bg-blue-600 mt-4"
      >
        Close
      </button>
    </div>
  );
};

export default UserInfo;
