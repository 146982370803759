import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  QuerySnapshot,
} from "firebase/firestore";
import Layout from "../Layout";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();

    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const firestore = getFirestore();
          const adminDoc = doc(firestore, "admin", user.email);

          try {
            const adminData = await getDoc(adminDoc);
            if (adminData.exists()) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          } catch (error) {
            console.error("Error checking admin status:", error);
          }
        }
        console.log("Is admin true or false: ", isAdmin);

        // If admin is already authenticated, redirect to admin dashboard
        if (isAdmin) {
          navigate("/admin-dashboard");
        }
      });

      return () => unsubscribe();
    }, [navigate, isAdmin]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);

      // Clear previous errors
      setError("");

      // Check if the logged-in user is an admin
      const firestore = getFirestore();
      const adminCollection = collection(firestore, "admin");

      try {
        const adminSnapshot = await getDocs(adminCollection);
        const adminArray = adminSnapshot.docs.map((doc) => doc.id);
        console.log("Checking admin ", adminArray);

        // Check if the inputted email exists in the adminArray

        console.log("Checking inputed email", email);
        const isVerified = adminArray.includes(email);

        console.log("Checking if it's verified:", isVerified);
        // setIsAdmin(isVerified);
        // Redirect admin to "/admin-dashboard" upon successful sign-in
        if (isVerified === true) {
          navigate("/admin-dashboard");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      setError("Invalid email or password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout>
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md flex flex-col min-h-screen">
        <h2 className="text-2xl font-bold mb-4">Admin Sign In</h2>
        <form onSubmit={handleSignIn}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2 12s3 5.5 6 9c3-3.5 6-9 6-9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 9l6 6 6-6"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold"
          >
            Sign In
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      </div>
    </Layout>
  );
};

export default AdminLogin;
