import React from "react";

const CardRating = ({ rating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <span
        key={i}
        className={i < rating ? "text-yellow-500" : "text-gray-300"}
      >
        &#9733;
      </span>
    );
  }
  return (
    <div className="text-center">
      <span>{rating}/5.0</span>
      <div className="mt-1">{stars}</div>
    </div>
  );
};

export default CardRating;
