import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../../firebase";
import Counter from "../Counter";
import Layout from "../Layout";
import LoadingCover from "../LoadingCover";
import Modal from "../Modal";
import StarRating from "../StarRating";
import ProductCarousel from "./ProductCarousel";
import ProductCard from "./ProductCard";
import { fetchFeaturedProducts } from "../../functions/functions";
import AllCards from "../../assets/cardImages/allCard.jpg";
import CustomerReviews from "../Customer/CustomerReviews";

const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [user, setUser] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const navigate = useNavigate();
  const locationData = JSON.parse(sessionStorage.getItem("countryRate"));
  const currencyCode = locationData.currencySymbol;
  const countryCode = locationData.countryCode;
  const currentRate = locationData.rate;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    async function fetchProductDetails() {
      try {
        setIsLoading(true);

        const productDoc = doc(firestore, "products", productId);
        const productData = await getDoc(productDoc);

        if (productData.exists()) {
          const productDetailsData = {
            id: productDoc.id,
            name: productData.data().name,
            ...productData.data(),
          };

          const imageName = `${productDetailsData.name.replace(/\s/g, "")}_${
            productDetailsData.name.length
          }`;
          const imageRef = ref(storage, `images/${imageName}.webp`);
          const imageUrl = await getDownloadURL(imageRef);

          productDetailsData.image = imageUrl;

          const reviewCollection = collection(firestore, "reviews");
          const reviewSnapshot = await getDocs(reviewCollection);
          const reviewsData = [];
          let totalRating = 0;

          reviewSnapshot.forEach((docSnap) => {
            const reviewData = docSnap.data();
            if (reviewData.product === productDetailsData.name) {
              reviewsData.push(reviewData);
              totalRating += reviewData.rating;
            }
          });

          const average =
            reviewsData.length > 0 ? totalRating / reviewsData.length : 0;
          setAverageRating(average.toFixed(1));

          setProductDetails(productDetailsData);
          setReviews(reviewsData);
        }
      } catch (error) {
        console.error("Error fetching product details: ", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { featuredProducts: fetchedFeatured, saleProducts: fetchedSale } =
          await fetchFeaturedProducts();
        setFeaturedProducts(fetchedFeatured);
        setSaleProducts(fetchedSale);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const addToCart = async () => {
    try {
      const cartData = JSON.parse(localStorage.getItem("cartData")) || [];

      const existingProductIndex = cartData.findIndex(
        (item) => item.id === productId
      );

      if (existingProductIndex !== -1) {
        cartData[existingProductIndex].quantity += quantity;
      } else {
        cartData.push({
          id: productId,
          name: productDetails.name,
          price: productDetails.price,
          image: productDetails.image,
          quantity: quantity,
        });
      }

      localStorage.setItem("cartData", JSON.stringify(cartData));
      setModalMessage("Product added to cart!");
      setIsModalOpen(true);

      if (user) {
        const userDocRef = doc(firestore, "users", user.email);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        userData.cart = cartData;
        await updateDoc(userDocRef, userData);

        setTimeout(() => {
          setIsModalOpen(false);
        }, 4500);
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const buyNow = async () => {
    await addToCart();
    navigate("/checkout");
  };

  if (isLoading) {
    return <LoadingCover />;
  }

  if (!productDetails) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Product Not Found</h1>
        <p className="text-lg">The product you're looking for doesn't exist.</p>
      </div>
    );
  }

  const discountedPrice = productDetails.salePrice || productDetails.price;
  const formattedPrice = (discountedPrice * currentRate).toFixed(2);

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity);
  };

  const openReviewModal = () => {
    setIsReviewModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
  };
  const handleScrollClick = () => {
    // Navigate to the product details page
    navigate(`/shop/${productId}`);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

   // Function to format the total price with commas and save printify products
   const formatTotalPrice = (price) => {
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <Layout>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <img
              src={productDetails.image}
              alt={productDetails.name}
              width={400}
              height={400}
            />
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-4">{productDetails.name}</h1>
            <span className="font-bold">
              <StarRating rating={averageRating || 0} />{" "}
            </span>

            <p className="text-2xl font-bold mb-4">
              {currencyCode} {formatTotalPrice(formattedPrice)}
            </p>
            <p className="text-lg mb-4">{productDetails.description}</p>

              <Counter quantity={quantity} setQuantity={setQuantity} />
            <div className="mt-4">
              <button
                className="bg-purple-900 hover:bg-green-600 text-white px-6 mt-5 py-3 rounded font-bold text-lg"
                onClick={addToCart}
                disabled={!productDetails.available}
              >
                Add to Cart
              </button>
              {isModalOpen && (
                <Modal message={modalMessage} onClose={closeModal} />
              )}

              {/* Payment Methods Accepted */}
              <div className="flex items-center mt-5 max-[777px]:justify-center">
                <div className="mr-4">
                  <p className="text-sm font-bold">We accept:</p>
                </div>
                <img
                  src={AllCards}
                  alt="Payments"
                  style={{ width: "35%" }}
                  className="w-10 mr-4 bg-gray-600"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerReviews reviews={reviews} averageRating={averageRating} />
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4">Featured Products</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {featuredProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetailsPage;
