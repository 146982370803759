import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import app from '../../firebase';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null); // Initialize as null for loading state
  const [loading, setLoading] = useState(true); // Loading state added

  const signUp = (email, password) => {
    return getAuth().createUserWithEmailAndPassword(email, password);
  };

  const signIn = (email, password) => {
    return getAuth().signInWithEmailAndPassword(email, password);
  };

  const signOut = () => {
    return getAuth().signOut();
  };

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        const firestore = getFirestore(app);
        const adminDoc = doc(firestore, 'admin', user.email);

        try {
          const adminData = await getDoc(adminDoc);
          setIsAdmin(adminData.exists());
        } catch (error) {
          console.error('Error checking admin status:', error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      } else {
        setIsAdmin(false); // Set isAdmin to false when there is no user
        setLoading(false); // Set loading to false
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    isAdmin,
    signUp,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only when loading is false */}
    </AuthContext.Provider>
  );
};
