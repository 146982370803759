import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaShoppingCart,
  FaList,
  FaUser,
  FaTshirt,
  FaEllipsisH,
  FaAddressBook,
  FaInfoCircle,
} from "react-icons/fa";
import HomeIcon from "./HomeIcon";
import useWindowSize from "../../../hooks/UseWindowSize";

const MobileNavBar = () => {
  const { width } = useWindowSize();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const toggleMoreMenu = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  };

  // Only render the mobile nav bar if the screen width is less than 600px
  if (width >= 600) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-yellow-400 border-t border-gray-200 flex justify-around items-center z-50">
      <Link
        to="/"
        className="flex flex-col items-center text-white transform translate-y-[-20px] focus:text-purple-700"
      >
        <HomeIcon size={64} />
        <span className="text-xs">Home</span>
      </Link>
      <Link
        to="/shop"
        className="flex flex-col items-center text-gray-700 focus:text-purple-700"
      >
        <FaTshirt size={24} />
        <span className="text-xs">Shop</span>
      </Link>
      <Link
        to="/categories"
        className="flex flex-col items-center text-gray-700 focus:text-purple-700"
      >
        <FaList size={24} />
        <span className="text-xs">Categories</span>
      </Link>

      <div className="relative">
        <button
          onClick={toggleMoreMenu}
          className="flex flex-col items-center text-gray-700 focus:text-purple-700"
        >
          <FaEllipsisH size={24} />
          <span className="text-xs">More</span>
        </button>

        {/* Drop-up menu */}
        {isMoreMenuOpen && (
          <div className="absolute bottom-full mb-2 -right-8 bg-yellow-400 rounded-sm overflow-hidden transition-all duration-300 ease-in-out transform translate-y-0 z-50">
            <ul className="text-gray-700 space-y-6 pt-2">
              <li>
                <Link
                  to="/profile"
                  className="flex items-center px-4 py-2 space-x-2 hover:bg-gray-200"
                  onClick={toggleMoreMenu}
                >
                  <FaUser size={16} />
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="flex items-center px-4 py-2 space-x-2 hover:bg-gray-200"
                  onClick={toggleMoreMenu}
                >
                  <FaAddressBook size={16} />
                  <span>Contact</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="flex items-center px-4 py-2 space-x-2 hover:bg-gray-200"
                  onClick={toggleMoreMenu}
                >
                  <FaInfoCircle size={16} />
                  <span>About</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavBar;
