import React from "react";

const Modal = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <p className="mb-4">{message}</p>
        <button
          onClick={onClose}
          className="py-2 px-4 bg-purple-700 text-white rounded hover:bg-purple-500"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
