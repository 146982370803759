import React, { useMemo, lazy, Suspense, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import LoadingCover from "../LoadingCover";
import FloatingCartIcon from "../Cart/FloatingCart";
import SlidingCartContainer from "../Cart/SlidingCartContainer";
import useCartData from "../../hooks/UseCartData";
import useProductsData from "../../hooks/UseProductsData";
import useMetadata from "../../hooks/UseMetadata";
import HeroSection from "./HeroSection";
import InfoCards from "./InfoCards";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import MobileNavBar from "../Mobile/Navigation/MobileNavbar";

// Lazy load components
const ProductSections = lazy(() => import("./ProductSections"));
const DiscountCard = lazy(() => import("./DiscountImage"));

const HomePage = () => {
  const { products, loading } = useProductsData();
  const { cartItems, setCartItems, authUser } = useCartData();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const locationData = JSON.parse(sessionStorage.getItem("countryRate")) || {};
  const currentRate = locationData.rate || 1;
  const currencyCode = locationData.currencySymbol || "$";

  const toggleCart = () => setIsCartOpen((prev) => !prev);

  const totalCartPrice = useMemo(
    () =>
      cartItems.reduce(
        (total, item) => total + item.price * item.quantity * currentRate,
        0
      ),
    [cartItems, currentRate]
  )
  
  const updateLocalStorage = (updatedCartItems) => {
    localStorage.setItem("cartData", JSON.stringify(updatedCartItems));
  };

  const updateFirestore = async (updatedCartItems) => {
    if (authUser) {
      try {
        const userCartDoc = doc(firestore, "users", authUser.email);
        await updateDoc(userCartDoc, { cart: updatedCartItems });
      } catch (error) {
        console.error("Error updating user cart data in Firestore: ", error);
      }
    }
  };

  const updateCartData = (updatedCartItems) => {
    setCartItems(updatedCartItems);
    updateLocalStorage(updatedCartItems);
    updateFirestore(updatedCartItems);
  };

  const updateQuantity = (productId, variantId, newQuantity) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === productId && item.variantId === variantId
        ? { ...item, quantity: newQuantity }
        : item
    );

    updateCartData(updatedCartItems);
  };

  const removeFromCart = (productId, variantId) => {
    const updatedCartItems = cartItems.filter(
      (item) => !(item.id === productId && item.variantId === variantId)
    );

    updateCartData(updatedCartItems);
  };

  return (
    <div className="overflow-x-hidden">
      <Navbar products={products} />
      <div className="flex flex-col min-h-screen mt-13">
        <HeroSection />
        <Suspense fallback={<LoadingCover />}>
          <ProductSections products={products} loading={loading} />
        </Suspense>
        <Suspense fallback={<LoadingCover />}>
          <DiscountCard />
        </Suspense>
        <InfoCards />
        <div id="modal-root"></div>
      </div>
      <FloatingCartIcon itemCount={cartItems.length} onClick={toggleCart} />
      <SlidingCartContainer
        cartItems={cartItems}
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        updateQuantity={updateQuantity}
        removeFromCart={removeFromCart}
        currencySymbol={currencyCode}
        cleanedCurrentRate={currentRate}
        totalCartPrice={totalCartPrice}
        authUser={authUser}
      />
      <div className="hidden max-[600px]:block">
        <MobileNavBar />
      </div>{" "}
      <Footer />
    </div>
  );
};

export default React.memo(HomePage);
