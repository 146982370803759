import React from "react";

const Counter = ({ quantity, setQuantity }) => {
  const handleIncrease = () => {
    if (quantity) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="flex items-center max-[777px]:justify-center space-x-5">
      <button
        onClick={handleDecrease}
        className="bg-gray-300 h-8 flex item-center font-bold text-gray-600 px-3 py-1 rounded hover:bg-gray-400"
      >
        -
      </button>
      <span className="text-xl font-semibold">{quantity}</span>
      <button
        onClick={handleIncrease}
        className="bg-gray-300 h-8 text-gray-600 px-3 py-1 rounded hover:bg-gray-400"
      >
        +
      </button>
    </div>
  );
};

export default Counter;
