import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchPrintifyProducts } from "../../functions";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../../firebase";
import Layout from "../../../Layout";
import ProductCard from "../ProductCard/ProductCard";
import CategoryFilter from "../Categories/CategoryFilter";
import banner from "../../../../assets/banners/Banner02.jpg";
import { FaFilter } from "react-icons/fa";
import useProductsData from "../../../../hooks/UseProductsData";

const ProductCollections = () => {
  const { categoryId } = useParams();
  const { products } = useProductsData();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 15;
  const [selectedCategory, setSelectedCategory] = useState(categoryId);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false); // State for modal visibility

  const filteredProducts = useMemo(() => {
    let filtered = products?.filter((product) => {
      return product?.title
        .toLowerCase()
        .includes(selectedCategory.toLowerCase());
    });

    if (selectedPrices.length > 0) {
      filtered = filtered.filter((product) => {
        const price = parseFloat(product.variants[0].price) / 100; // Convert price to dollars
        if (selectedPrices.includes("under10") && price < 10) return true;
        if (selectedPrices.includes("10to30") && price >= 10 && price <= 30)
          return true;
        if (selectedPrices.includes("30to50") && price > 30 && price <= 50)
          return true;
        if (selectedPrices.includes("50to70") && price > 50 && price <= 70)
          return true;
        if (selectedPrices.includes("over70") && price > 70) return true;
        return false;
      });
    }

    return filtered;
  }, [products, selectedCategory, selectedPrices]);

  const currentProducts = useMemo(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return filteredProducts?.slice(indexOfFirstProduct, indexOfLastProduct);
  }, [currentPage, filteredProducts]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleToggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  return (
    <Layout>
      <div
        className="bg-gray-400 text-black py-20 px-28 mx-0 relative max-[482px]:hidden"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto mt-14 text-center">
          <h1 className="text-5xl font-bold mb-4">
            {selectedCategory} Collection
          </h1>
          <p className="text-md mb-8 text-wrap">
            Discover our amazing {selectedCategory} collection and shop with
            ease. Whether you’re just starting out or you're a pro, your style
            is like a fingerprint.
          </p>
        </div>
      </div>
      <div
        className="bg-gray-400 text-black text-center pt-14 pb-8 relative hidden max-[482px]:block"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto mt-14 text-center">
          <h1 className="text-5xl font-bold mb-4">
            {selectedCategory} Collection
          </h1>
          <p className="text-md mb-8 text-wrap">
            Discover our amazing {selectedCategory} collection and shop with
            ease. Whether you’re just starting out or you're a pro, your style
            is like a fingerprint.
          </p>
        </div>
      </div>
      <div className="container mx-auto py-8 lg:px-6 sm:px-2 max-[650px]:px-0 flex max-[767px]:flex-col">
        <div className="hidden lg:block md:block md:w-1/5 w-1/4">
          <CategoryFilter
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedPrices={selectedPrices}
            setSelectedPrices={setSelectedPrices}
          />
        </div>
        {/* Filter Button for Small Screens */}
        <div className="lg:hidden md:hidden text-center my-4 mx-6 bg-gray-400">
          <button
            onClick={handleToggleFilterModal}
            className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-2 rounded flex items-center justify-between font-bold"
          >
            <FaFilter className="text-black" />
            Filter
          </button>
        </div>
        <div className="w-full lg:w-3/4 md:w-4/5 mx-auto">
          <h1 className="text-dark font-bold text-2xl mx-6">
            {selectedCategory}
          </h1>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-[767px]:gap-0 gap-2">
            {currentProducts?.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
          <div className="flex justify-evenly my-6">
            {currentProducts.length > 5 ? (
              <>
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="bg-purple-900 hover:bg-yellow-600 text-white px-4 py-2 rounded font-bold mx-2"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={currentProducts.length < productsPerPage}
                  className="bg-purple-900 hover:bg-yellow-500 text-white px-4 py-2 rounded font-bold mx-2"
                >
                  Next
                </button>
              </>
            ) : (
              ""
            )}
          </div>
          {/* Modal for Filter */}
          {showFilterModal && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <CategoryFilter
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedPrices={selectedPrices}
                  setSelectedPrices={setSelectedPrices}
                />
                <button
                  className="text-dark font-bold hover:text-gray-800 hover:bg-purple-500 py-2 px-4 rounded"
                  onClick={handleToggleFilterModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div id="modal-root"></div>
    </Layout>
  );
};

export default ProductCollections;
