// PostForm.js
import React, { useState } from "react";
import addPost from "../UploadProducts/AddPost";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";
import app from "../../../../firebase";

import AdminLayout from "../../AdminLayout";

const storage = getStorage(app);

const PostForm = () => {
  const [postDetails, setPostDetails] = useState({
    author: "",
    publishDate: "", // You may need to handle the date field appropriately
    category: "",
    title: "",
    subtitle: "",
    content: "",
    image: null,
  });

  const generateImageName = (postTitle) => {
    const imageName = `${postTitle.replace(/\s/g, "")}_${postTitle.length}`;
    return imageName;
  };

  const uploadImageToStorage = async (file, postTitle) => {
    const imageName = generateImageName(postTitle);
    const newStorageRef = storageRef(storage, "Blogpost-images/" + imageName);
    await uploadBytes(newStorageRef, file);
    const downloadURL = await getDownloadURL(newStorageRef);
    return downloadURL;
  };

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      setPostDetails({ ...postDetails, [name]: files[0] });
    } else {
      setPostDetails({ ...postDetails, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const imageUrl = await uploadImageToStorage(
        postDetails.image,
        postDetails.title
      );

      setPostDetails({ ...postDetails, image: imageUrl });

      await addPost(postDetails);

      setShowSuccessModal(true);

      setTimeout(() => {
        setShowSuccessModal(false);
        setPostDetails({
          author: "",
          publishDate: "", // You may need to handle the date field appropriately
          category: "",
          title: "",
          subtitle: "",
          content: "",
          image: null,
        });
      }, 2000);
    } catch (error) {
      console.error("Error adding post: ", error);
    }
  };

  return (
    <AdminLayout>
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Add Post</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="author"
            >
              Author
            </label>
            <input
              type="text"
              id="author"
              name="author"
              value={postDetails.author}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="publishDate"
            >
              Publish Date
            </label>
            <input
              type="text" // You may need to replace this with a suitable date input
              id="publishDate"
              name="publishDate"
              value={postDetails.publishDate}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="category"
            >
              Category
            </label>
            <input
              type="text"
              id="category"
              name="category"
              value={postDetails.category}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="title"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={postDetails.title}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="subtitle"
            >
              Subtitle
            </label>
            <input
              type="text"
              id="subtitle"
              name="subtitle"
              value={postDetails.subtitle}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="content"
            >
              Content
            </label>
            <textarea
              id="content"
              name="content"
              value={postDetails.content}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="image"
            >
              Image
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold"
            >
              Add Post
            </button>
          </div>
        </form>

        {/* Success Modal */}
        {showSuccessModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-md">
              <p className="text-green-500 font-bold text-lg mb-4">
                Post uploaded successfully!
              </p>
              <button
                onClick={() => setShowSuccessModal(false)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full font-bold"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default PostForm;
