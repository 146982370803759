import React from "react";
import { Link } from "react-router-dom";
import Layout from "./components/Layout";

const NotFound = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <h1 className="text-6xl font-bold text-purple-700">404</h1>
        <p className="text-2xl mt-4">Page Not Found</p>
        <p className="text-gray-600 mt-2">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="mt-6 bg-purple-700 text-white py-2 px-4 rounded hover:bg-purple-600 transition duration-300"
        >
          Go Back Home
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
