import React from "react";
import Navbar from "./SubDirectories/DemoBrand/Navbar/Navbar";

const PaymentSuccess = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
