import React from "react";
import Form from "./Form";
import Layout from "../Layout";
import Address from "./Address";
import Footer from "../Footer/Footer";

const Contact = () => {
  return (
    <Layout>
      <div className="">
        <div className="flex flex-col items-center pt-6">
          <div className="w-full md:w-2/3 mb-8">
            <Form />
          </div>
          <div className="w-full md:w-2/3 mb-8">
            <Address />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
