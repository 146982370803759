import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

const NavList = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [chunkedLinks, setChunkedLinks] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  useEffect(() => {
    const links = [
      { to: "/shop", label: "Shop" },
      { to: "/about", label: "About" },
      { to: "/contact", label: "Contact" },
      { to: "/order-tracking", label: "Track Orders" },
    ];

    const chunked = [];
    for (let i = 0; i < links.length; i += 3) {
      chunked.push(links.slice(i, i + 3));
    }
    setChunkedLinks(chunked);
  }, []);

  return (
    <div className="flex font-bold justify-around text-white">
      {chunkedLinks.map((chunk, chunkIndex) => (
        <ul key={chunkIndex} className="mb-2">
          {chunk.map((link, linkIndex) => (
            <li key={link.to} className="block px-4 py-2 hover:text-yellow-600">
              <Link
                to={link.to}
                className={`${linkIndex === 0 ? "font-bold" : ""}`}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

export default NavList;
