import React from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/banners/Banner05.jpg";

const HeroSection = () => {
  return (
    <>
      {/* Preload the desktop banner image */}
      <link
        rel="preload"
        href={banner}
        as="image"
        type="image/jpeg"
        media="(min-width: 778px)"
        fetchpriority="high"
      />

      {/* Desktop Hero Section */}
      <div
        className="bg-gray-400 text-black py-40 px-36 relative max-[777px]:hidden"
        id="hero-image"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto mt-14">
          <h1 className="text-5xl font-bold text-left mb-4">
            Welcome to Ogascounty
            <br />
            Where fashion meets faith.
          </h1>
          <p className="text-md mb-8 font-semibold text-left text-wrap">
            Discover amazing products and shop with ease
            <br /> whether you’re just starting out, have shopped your whole
            life <br /> or you're a pro, your style is like a fingerprint.
          </p>
          <Link
            to="/shop"
            className="bg-purple-900 hover:bg-purple-600 text-white px-6 py-3 rounded font-bold text-lg"
          >
            Shop Now
          </Link>
        </div>
      </div>

      {/* Mobile Hero Section with Gradient Background */}
      <div
        className="text-black text-center pt-14 pb-8 relative hidden max-[777px]:block"
        id="hero-mobile-version"
        style={{
          background: "linear-gradient(to right, #d3cce3, #e9e4f0)", // Light purple and yellow gradient
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto">
          <h1 className="text-lg font-bold mb-4">
            Welcome to Ogascounty
            <br />
            Where fashion meets faith.
          </h1>
          <p className="text-l mb-8">
            Discover amazing products and shop with ease.
          </p>
          <div className="text-center">
            <Link
              to="/shop"
              className="bg-purple-900 hover:bg-purple-600 text-white px-4 py-2 rounded font-bold text-l"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
