import React, { useState } from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Layout from "../Layout";
import { useNavigate, Link } from "react-router-dom";
import banner from "../../assets/discountCard.avif";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateAccountComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [failModal, setFailModal] = useState(false);

  const navigate = useNavigate();
  const firestore = getFirestore();

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    try {
      // Create user account
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Add user email to Firestore users collection
      const userDocRef = doc(firestore, "users", userCredential.user.email);
      await setDoc(userDocRef, {
        displayName: "",
        contactInformation: {
          address: "",
          country: "",
          state: "",
          city: "",
          postalCode: "",
          phone: "",
        },
        orders: [],
      });

      // Show success modal
      setSuccessModal(true);

      // Redirect to home page upon successful account creation
      setTimeout(() => {
        setSuccessModal(false);
        navigate("/");
      }, 2000);
    } catch (error) {
      // Handle account creation error
      setError(error.message);
      setFailModal(true);

      // Hide fail modal after 2 seconds
      setTimeout(() => {
        setFailModal(false);
      }, 2000);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen">
        <div
          className="w-full md:w-1/2 bg-cover bg-center h-64 md:h-auto"
          style={{
            backgroundImage: `url(${banner})`,
          }}
        >
          {/* You can replace the URL with the actual image path */}
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div className="max-w-md p-6 bg-white rounded-md w-full">
            <h2 className="text-3xl font-bold mb-4">Create Account</h2>
            <form onSubmit={handleCreateAccount}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>
              {error && (
                <div className="text-red-500 mb-4">
                  <p>{error}</p>
                </div>
              )}
              <button
                type="submit"
                className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-md font-bold w-full"
              >
                Create Account
              </button>
            </form>

            {/* Success Modal */}
            {successModal && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
                <div className="bg-white p-8 rounded-md">
                  <p className="text-green-500 font-bold text-lg">
                    Account created successfully!
                  </p>
                </div>
              </div>
            )}

            {/* Fail Modal */}
            {failModal && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
                <div className="bg-white p-8 rounded-md">
                  <p className="text-red-500 font-bold text-lg">
                    Account creation failed. Please try again.
                  </p>
                </div>
              </div>
            )}

            <div className="mt-4 text-center">
              <p>
                Already have an account?{" "}
                <Link to="/signin" className="text-blue-500">
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateAccountComponent;
