import React from "react";

const UserList = ({ users, fetchUserInfo, deleteUser, handleEditUser }) => {
  return (
    <table className="min-w-full bg-white border border-gray-300 text-center mb-8">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b">Index</th>
          <th className="py-2 px-4 border-b">User ID</th>
          <th className="py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={user.id}>
            <td className="py-2 px-4 border-b">{index + 1}</td>
            <td className="py-2 px-4 border-b">{user.id}</td>
            <td className="py-2 px-4 border-b">
              <button
                onClick={() => fetchUserInfo(user.id)}
                className="bg-blue-500 text-white px-3 py-1 rounded-full hover:bg-blue-600"
              >
                View
              </button>
              <button
                onClick={() => handleEditUser(user)}
                className="bg-yellow-500 text-white px-3 py-1 rounded-full hover:bg-yellow-600 ml-2"
              >
                Edit
              </button>
              <button
                onClick={() => deleteUser(user.id)}
                className="bg-red-500 text-white px-3 py-1 rounded-full hover:bg-red-600 ml-2"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserList;
