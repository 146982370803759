import React from "react";
import NavList from "../SubDirectories/DemoBrand/Navbar/NavList";
import Logo from "../SubDirectories/DemoBrand/Navbar/Logo";
import EmailNewsletter from "./EmailNewsletter";

const Footer = () => {
  return (
    <>
      <EmailNewsletter />
      <footer className="bg-purple-700 text-white py-8 mt-auto">
        <div className="container mx-auto flex flex-col max-[767px]:flex-col-reverse lg:flex-row lg:justify-between items-start">
          {/* Logo and Copyright */}
          <div className="flex flex-col items-center text-center mx-auto lg:items-start lg:w-1/3 mb-8 lg:mb-0">
            <div className="p-2 flex justify-center w-full lg:w-auto">
              <Logo />
            </div>
            <p className="text-sm mt-4 text-center lg:text-left w-full lg:w-auto">
              &copy; 2024 Ogascounty. All rights reserved.
            </p>
          </div>

          {/* Navigation List */}
          <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
            <NavList />
          </div>

          {/* Email Newsletter */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
