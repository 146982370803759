import React from "react";

const CustomerInformationCard = ({
  fullName,
  email,
  phone,
  address,
  city,
  state,
  country,
  postalCode,
}) => {
  return (
    <div className="bg-transparent p-4">
      <h3 className="font-bold text-xl mb-2">Customer Information</h3>
      <p>
        <strong>Full Name:</strong> {fullName}
      </p>
      <p>
        <strong>Email:</strong> {email}
      </p>
      <p>
        <strong>Phone:</strong> {phone}
      </p>
      <p>
        <strong>Address:</strong> {address}, {city}, {state}, {country},{" "}
        {postalCode}
      </p>
    </div>
  );
};

export default CustomerInformationCard;
