import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import useWindowSize from "../../hooks/UseWindowSize";

const FloatingCartIcon = ({ itemCount, onClick }) => {
  const {width} = useWindowSize();
  if(width < 600){
    return null; // Hide the icon on mobile devices.
    // You can also return a different UI element or component here.
  }
  return (
    <div
      className="fixed bottom-4 z-50 left-4 bg-purple-600 text-white p-4 rounded-full shadow-lg cursor-pointer"
      onClick={onClick}
    >
      <AiOutlineShoppingCart size={24} />
      {itemCount > 0 && (
        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
          {itemCount}
        </span>
      )}
    </div>
  );
};

export default FloatingCartIcon;
