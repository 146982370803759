import HomePage from "./Home/home";
import ProductForm from "./adminComponents/Uploads/UploadProducts/AddProductsForm";
import ProductList from "./adminComponents/ProductList";
import ProductDetails from "./productCard/ProductDetails";
import CreateAccountComponent from "./UserAuth/CreateAccount";
import ForgotPasswordComponent from "./ForgotPassword";
import SignInComponent from "./UserAuth/Signin";
import Cart from "./printifyShop/Cart/Cart";
import UserProfile from "./UserAuth/profile/UserProfile";
import Checkout from "./Checkout/Checkout";
import AdminLogin from "./adminComponents/AdminLogin";
import AdminDashboard from "./adminComponents/Dashboard";
import Blog from "./Blog";
import PostForm from "./adminComponents/Uploads/UploadBlog/PostsForm";
import PostPage from "./PostPage";
import ViewUsers from "./adminComponents/Users/ViewUsers";
import QueryResult from "./QueryResult";
import TrackingOrder from "./TrackingOrder";
import PaymentSuccess from "./PaymentSuccess";
import Contact from "./Contact/Contact";
import Orders from "./adminComponents/Orders/Orders";
import MainPrintifyProductDetails from "./printifyShop/Products/ProductDetails/MainProductDetails";
import Shop from "./printifyShop/Shop/Shop";
import ProductCollections from "./printifyShop/Products/ProductCollections/ProductCollections";
import NotFound from "../404";

const delayRendering = async (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
    delay: 2000,
  },
  {
    path: "/admin-dashboard/product-upload",
    component: ProductForm,
    delay: 2000,
  },
  {
    path: "/admin-dashboard/products",
    component: ProductList,
    delay: 2000,
  },
  {
    path: "/shop",
    component: Shop,
    delay: 2000,
  },
  // {
  //   path: "/shop/:productId",
  //   component: ProductDetails,
  //   delay: 2000,
  // },
  {
    path: "/shop/:productId",
    component: MainPrintifyProductDetails,
    delay: 2000,
  },

  {
    path: "/signup",
    component: CreateAccountComponent,
    delay: 2000,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordComponent,
    delay: 2000,
  },
  {
    path: "/signin",
    component: SignInComponent,
    delay: 2000,
  },
  {
    path: "/shop/:productId/cart",
    component: Cart,
    delay: 2000,
  },
  {
    path: "/cart",
    component: Cart,
    delay: 2000,
  },
  {
    path: "/product-collections/:categoryId",
    component: ProductCollections,
    delay: 2000,
  },
  {
    path: "/contact",
    component: Contact,
    delay: 2000,
  },
  {
    path: "/profile",
    component: UserProfile,
    delay: 2000,
  },
  {
    path: "/shop/products/cart/checkout",
    component: Checkout,
    delay: 2000,
  },
  {
    path: "/admin-login",
    component: AdminLogin,
    delay: 2000,
  },
  {
    path: "/admin-dashboard/*",
    component: AdminDashboard,
    delay: 2000,
  },
  {
    path: "/blog",
    component: Blog,
    delay: 2000,
  },
  {
    path: "/admin-dashboard/blog-post-upload",
    component: PostForm,
    delay: 2000,
  },
  {
    path: "/admin-dashboard/orders",
    component: Orders,
    delay: 2000,
  },
  {
    path: "/blog/:title",
    component: PostPage,
    delayRendering: true,
  },
  {
    path: "/admin-dashboard/users",
    component: ViewUsers,
    delay: 2000,
  },
  {
    path: "/queryresult",
    component: QueryResult,
    delay: 2000,
  },
  {
    path: "/order-tracking",
    component: TrackingOrder,
    delay: 2000,
  },
  {
    path: "/checkout/success",
    component: PaymentSuccess,
    delay: 2000,
  },
  // {
  //   path: "/404",
  //   component: NotFound,
  //   exact: true,
  //   delay: 2000,
  // }
];

export default routes;
