import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import app from "../firebase";
import { storage } from "../firebase";
import BlogNavbar from "./BlogNavBar";
import BlogLayout from "./BlogLayout";

const firestore = getFirestore(app);

const PostPage = () => {
  // Get the post title from the URL params
  const { title } = useParams();
  title.replace(/\s+/g, "-");

  // State to store the post data
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        // Fetch post data from Firestore
        const postDoc = doc(firestore, "blog", title); // Assuming 'blog' is your collection name
        const postSnapshot = await getDoc(postDoc);

        if (postSnapshot.exists()) {
          const postData = postSnapshot.data();

          // Fetch image URL from Storage
          // Construct the image URL based on the post title
          const imageName = `${postData.title.replace(/\s/g, "")}_${
            postData.title.length
          }`;
          const imageRef = storageRef(storage, `Blogpost-images/${imageName}`);
          const imageUrl = await getDownloadURL(imageRef);

          // Update the post data with the image URL
          setPost({ ...postData, imageUrl });
        } else {
          console.error(`Post with title ${title} not found`);
          // You can handle this case based on your application's requirements
        }
      } catch (error) {
        console.error("Error fetching post data: ", error);
      }
    };

    fetchPostData();
  }, [title]);

  if (!post) {
    return <p className="text-red-500">Loading Post...</p>;
  }

  return (
    <BlogLayout>
      <div className="container mx-auto my-8">
        <div className="bg-white p-8 rounded-md shadow-md">
          {/* Author, Publish Date, and Category */}
          <div className="flex items-center mb-4">
            <span className="text-gray-500 mr-2">
              By <strong>{post.author}</strong>
            </span>
            <div className="text-gray-500 mr-2">
              Published on {post.publishDate}
            </div>
            <span className="text-gray-500">Category: {post.category}</span>
          </div>

          {/* Title */}
          <h2 className="text-4xl font-bold mb-4">{post.title}</h2>

          {/* Subtitle */}
          {post.subtitle && (
            <p className="text-gray-700 mb-4">{post.subtitle}</p>
          )}

          {/* Image */}
          {post.imageUrl && (
            <img
              src={post.imageUrl}
              alt={post.title}
              className="w-full h-auto mb-6 rounded-md"
            />
          )}

          {/* Full content */}
          <p className="text-gray-700 mb-8">{post.content}</p>

          {/* Views Counter */}
          <div className="flex items-center">
            <span className="text-gray-500 mr-2">Views: {post.views}</span>
          </div>
        </div>
      </div>
    </BlogLayout>
  );
};

export default PostPage;
