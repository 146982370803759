import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { fetchPrintifyProducts } from "../components/printifyShop/functions";
import { firestore } from "../firebase";
// import { useDispatch } from "react-redux";
// import { setProductState } from "../reducers/ProductSlice";

const useProductsData = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  // const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductsAndReviews = async () => {
      try {
        const data  = await fetchPrintifyProducts();

        // Dispatch to Redux store
        // dispatch(setProductState(data));
        
        const reviewCollection = collection(firestore, "reviews");
        const reviewSnapshot = await getDocs(reviewCollection);
        const reviewsData = reviewSnapshot.docs.map((doc) => doc.data());

        const productsWithReviews = data.map((product) => {
          const productReviews = reviewsData.filter(
            (review) => review.product.productName === product.title
          );

          const totalRating = productReviews.reduce(
            (sum, review) => sum + review.rating,
            0
          );

          const averageRating =
            productReviews.length > 0
              ? (totalRating / productReviews.length).toFixed(1)
              : 0;

          return {
            ...product,
            averageRating,
          };
        });

        setProducts(productsWithReviews);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsAndReviews();
  }, []);

  return { products, loading };
};

export default useProductsData;
