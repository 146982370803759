import React from "react";
import banner from "../../../assets/banners/Banner02.jpg";

const ShopBanner = () => {
  return (
    <>
      <div
        className="bg-gray-400 text-black py-20 px-32 mx-0 relative max-[482px]:hidden"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto mt-14 text-center">
          <h1 className="text-5xl font-bold mb-4">Shop Page</h1>
          <p className="text-md mb-8 text-wrap">
            Discover amazing products and shop with ease
            <br /> whether you’re just starting out, have shopped your whole
            life <br /> or you're a pro, your style is like a fingerprint.
          </p>
        </div>
      </div>
      <div
        className="bg-gray-400 text-black text-center pt-14 pb-8 relative hidden max-[482px]:block"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto mt-14 text-center">
          <h1 className="text-5xl font-bold mb-4">Shop Page</h1>
          <p className="text-md mb-8 text-wrap">
            Discover amazing products and shop with ease
            <br /> whether you’re just starting out, have shopped your whole
            life <br /> or you're a pro, your style is like a fingerprint.
          </p>
        </div>
      </div>
    </>
  );
};

export default ShopBanner;
