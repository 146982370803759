import React, { useState, useEffect, useMemo } from "react";
import ProductCard from "../Products/ProductCard/ProductCard";
import Layout from "../../Layout";
import useProductsData from "../../../hooks/UseProductsData";
import ShopBanner from "./ShopBanner"; // Import the ShopBanner component
import ShopFilters from "./ShopFilters"; // Import the ShopFilters component


const categories = [
  { title: "All", filter: [] },
  { title: "T-shirt", filter: ["tee"] },
  { title: "Sweatshirt", filter: ["sweatshirt"] },
  { title: "Joggers", filter: ["joggers"] },
];

const Shop = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOption, setSortOption] = useState("title");
  const { products } = useProductsData();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 15;

  const filteredProducts = useMemo(() => {
    let categoryFilteredProducts = products?.filter((product) => {
      return (
        selectedCategory === "All" ||
        categories.some(
          (cat) =>
            cat.title === selectedCategory &&
            cat.filter.some((keyword) =>
              product.title.toLowerCase().includes(keyword)
            )
        )
      );
    });

    if (sortOption === "title") {
      categoryFilteredProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOption === "priceAsc") {
      categoryFilteredProducts.sort((a, b) => a.cost - b.cost);
    } else if (sortOption === "priceDesc") {
      categoryFilteredProducts.sort((a, b) => b.cost - a.cost);
    }

    return categoryFilteredProducts;
  }, [products, selectedCategory, sortOption]);

  const currentProducts = useMemo(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  }, [currentPage, filteredProducts]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  useEffect(() => {
    // Scroll back to the top of the page when currentPage changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to the first page when category changes
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
  };

  return (
    <Layout>
      <ShopBanner /> {/* Use the ShopBanner component */}
      <div className="container mx-auto py-8 lg:px-28 md:px-8 sm:px-6 max-[650px]:px-0">
        <ShopFilters
          selectedCategory={selectedCategory}
          sortOption={sortOption}
          handleCategoryChange={handleCategoryChange}
          handleSortOptionChange={handleSortOptionChange}
        />{" "}
        {/* Use the ShopFilters component */}
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 max-[767px]:gap-1 gap-4">
          {currentProducts?.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
        <div className="flex justify-evenly my-6">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="bg-purple-900 hover:bg-yellow-600 text-white px-4 py-2 rounded font-bold mx-2"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentProducts.length < productsPerPage}
            className="bg-purple-900 hover:bg-yellow-500 text-white px-4 py-2 rounded font-bold mx-2"
          >
            Next
          </button>
        </div>
      </div>
      <div id="modal-root"></div>
    </Layout>
  );
};

export default Shop;
