import React, { useEffect, useState } from "react";
import Counter from "../../Counter";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import CartSummary from "./CartSummary";

import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import Layout from "../../Layout";
import { formatNumber } from "../functions";
import MobileNavBar from "../../Mobile/Navigation/MobileNavbar";

const Cart = ({ brandsData }) => {
  const [cartItems, setCartItems] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const locationData = JSON.parse(sessionStorage.getItem("countryRate"));
  const currencyCode = locationData?.currencySymbol;
  // const countryCode = locationData?.countryCode;
  const currentRate = locationData?.rate;

  const cleanedCurrentRate =
    currentRate === undefined ? 1 : parseInt(currentRate.toFixed(2));
  const cleaedCurrencyCode = currencyCode === undefined ? "$" : currencyCode;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) setAuthUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      if (authUser) {
        try {
          const userCartDoc = doc(firestore, "users", authUser?.email);
          const userCartData = await getDoc(userCartDoc);

          if (userCartData.exists()) {
            const userCartItems = userCartData.data().cart || [];
            setCartItems(userCartItems);
          }
        } catch (error) {
          console.error("Error fetching user cart data: ", error);
        }
      } else {
        const storedCartData =
          JSON.parse(localStorage.getItem("cartData")) || [];
        setCartItems(storedCartData);
      }
    };

    fetchCartData();
  }, [authUser]);

  const updateLocalStorage = (updatedCartItems) => {
    localStorage.setItem("cartData", JSON.stringify(updatedCartItems));
  };

  const updateFirestore = async (updatedCartItems) => {
    if (authUser) {
      try {
        const userCartDoc = doc(firestore, "users", authUser?.email);
        await updateDoc(userCartDoc, { cart: updatedCartItems });
      } catch (error) {
        console.error("Error updating user cart data in Firestore: ", error);
      }
    }
  };

  const updateCartData = (updatedCartItems) => {
    setCartItems(updatedCartItems);
    updateLocalStorage(updatedCartItems);
    updateFirestore(updatedCartItems);
  };

  const updateQuantity = (productId, variantId, newQuantity) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === productId && item.variantId === variantId
        ? { ...item, quantity: newQuantity }
        : item
    );

    updateCartData(updatedCartItems);
  };

  const removeFromCart = (productId, variantId) => {
    const updatedCartItems = cartItems.filter(
      (item) => !(item.id === productId && item.variantId === variantId)
    );

    updateCartData(updatedCartItems);
  };

  const uniqueProductIds = [...new Set(cartItems.map((item) => item.id))];
  const cartItemCount = uniqueProductIds.length;
  const totalCartPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity * cleanedCurrentRate,
    0
  );

  // if (cartItemCount === 0) {
  //   return (
  //     <Layout>
  //       <div className="container mx-auto my-16 text-center">
  //         <h2 className="text-4xl font-bold pb-8 mt-20 pt-20">
  //           Your Cart is Empty
  //         </h2>
  //         <Link to="/shop" className="text-yellow-400 m-8 pointer">
  //           Go back to Shop
  //         </Link>
  //       </div>
  //     </Layout>
  //   );
  // }

  return (
    <div className="overflow-x-hidden">
      <Navbar products={cartItems} />
      {cartItemCount === 0 ? (
        <div className="container mx-auto mb-6 text-center">
          <h2 className="text-4xl font-bold pb-8 mt-20 pt-20">
            Your Cart is Empty
          </h2>
          <Link to="/shop" className="text-yellow-400 m-8 pointer">
            Go back to Shop
          </Link>
        </div>
      ) : (
        <>
          <div className="hidden md:block lg:block">
            <div className="container md:mt-8 mx-auto content-center min-h-screen px-16">
              <h2 className="text-3xl mt-10 pt-0 font-bold mb-8">
                Shopping Cart
              </h2>
              <div className="grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-8">
                <div className="col-span-2 shadow-md rounded pb-4">
                  <table className="min-w-full bg-white text-center">
                    <thead>
                      <tr className="border-b">
                        <th className="py-2 px-10 text-left">Product</th>
                        <th className="py-2 px-4">Quantity</th>
                        <th className="py-2 px-4">Price</th>
                        <th className="py-2 px-4">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td className="py-2 px-4 flex items-center text-left">
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-28 h-auto object-cover mr-4"
                            />
                            <div>
                              <div>{item.name}</div>
                              <div className="text-sm text-gray-500">
                                Color: {item.color}
                              </div>
                              <div className="text-sm text-gray-500">
                                Size: {item.size}
                              </div>
                            </div>
                          </td>
                          <td className="py-2 px-4">
                            <Counter
                              quantity={item.quantity}
                              setQuantity={(newQuantity) =>
                                updateQuantity(
                                  item.id,
                                  item.variantId,
                                  newQuantity
                                )
                              }
                            />
                          </td>
                          <td className="py-2 px-4">
                            {cleaedCurrencyCode}
                            {formatNumber(
                              item.price * item.quantity * cleanedCurrentRate
                            )}
                          </td>
                          <td className="py-2 px-4">
                            <button
                              onClick={() =>
                                removeFromCart(item.id, item.variantId)
                              }
                              className="bg-red-500 text-white px-3 py-1 rounded-full hover:bg-red-600"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-span-1">
                  <CartSummary
                    cartItems={cartItems}
                    totalCartPrice={totalCartPrice}
                    currentRate={currentRate}
                    currencySymbol={currencyCode}
                    authUser={authUser}
                  />
                </div>
              </div>

              {/* Button to go to Checkout */}
              {cartItemCount > 0 && (
                <div className="mt-8 text-center">
                  <Link
                    to="/shop/products/cart/checkout"
                    className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                  >
                    Proceed to Checkout
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="overflow-x-hidden hidden max-[767px]:block">
            <div className="container mx-auto mt-4 content-center min-h-screen">
              <h2 className="text-3xl font-bold mb-8">Shopping Cart</h2>
              <div className="overflow-x-auto">
                {cartItems.map((item) => (
                  <div key={item.id} className="bg-white rounded-md p-4 mb-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-4 gap-4">
                        {/* Image (1st column) */}
                        <div className="col-span-1 w-30">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-30 h-30 object-cover"
                          />
                        </div>

                        {/* Product Details (2nd column) */}
                        <div className="col-span-3 flex flex-col">
                          <div className="lg:flex-1 lg:mr-4 font-bold">
                            {item.name}
                          </div>
                          <div className="text-gray-600">
                            Color: {item.color}
                          </div>

                          <div className="text-gray-600">Size: {item.size}</div>
                          <div>
                            Price:
                            {cleaedCurrencyCode}
                            {(
                              item.price *
                              item.quantity *
                              cleanedCurrentRate
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>

                      {/* Counter and Remove Button (3rd and 4th columns) */}
                      <div className="grid grid-cols-2 gap-2">
                        {/* Remove Button (1st column) */}
                        <div className="col-span-1 flex flex-col">
                          <button
                            onClick={() =>
                              removeFromCart(item.id, item.variantId)
                            }
                            className="bg-red-500 text-white py-1 rounded mb-0"
                          >
                            {/* <AiFillDelete /> */}
                            Remove Product
                          </button>
                        </div>

                        {/* Counter (2nd column) */}
                        <div className="col-span-1 flex flex-col">
                          <Counter
                            quantity={item.quantity}
                            setQuantity={(newQuantity) =>
                              updateQuantity(
                                item.id,
                                item.variantId,
                                newQuantity
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 gap-4">
                <div className="col-span-1">
                  <CartSummary
                    cartItems={cartItems}
                    totalCartPrice={totalCartPrice}
                    currentRate={currentRate}
                    currencySymbol={currencyCode}
                    authUser={authUser}
                  />
                </div>
              </div>

              {/* Button to go to Checkout */}
              {cartItemCount > 0 && (
                <div className="mt-8 text-center">
                  <Link
                    to="/shop/products/cart/checkout"
                    className="bg-purple-900 text-white px-4 py-2 rounded hover:bg-purple-600"
                  >
                    Proceed to Checkout
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="hidden max-[600px]:block">
        <MobileNavBar />
      </div>{" "}
      <Footer />
    </div>
  );
};

export default Cart;
