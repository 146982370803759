import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";

export const fetchPrintifyProducts = async () => {

  const fetchAllProducts = async () => {
    // Fetch data from Firestore
    const productsCollection = collection(firestore, "PrintifyProducts");
    const snapshot = await getDocs(productsCollection);
    const data = snapshot.docs.map((doc) => doc.data());

    // Update localStorage with fresh data and current timestamp
    localStorage.setItem(
      "printifyProducts",
      JSON.stringify({ data, timestamp: Date.now() })
    );

    return data;
  };

  try {
    // Check if data exists in localStorage
    const cachedData = localStorage.getItem("printifyProducts");

    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);

      // Determine cache validity (e.g., 24 hours)
      const cacheValidity = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const isCacheValid = Date.now() - timestamp < cacheValidity;

      if (isCacheValid) {
        return data;
      }
    }

    // If no valid cache, fetch fresh data
    return await fetchAllProducts();
  } catch (error) {
    console.error("Error fetching Printify products:", error);
    throw error;
  }
};

// Function to fetch single product data from localStorage or Firestore
export const fetchSingleProductData = async (productName) => {

  const findProductInLocalStorage = (products) => {
    return products.find((product) => product.title === productName);
  };

  try {
    // Check if the entire product collection exists in localStorage
    const cachedProductsData = localStorage.getItem("printifyProducts");

    if (cachedProductsData) {
      const { data, timestamp } = JSON.parse(cachedProductsData);
      // console.log("Fetched data from localstorage:", data)

      // Determine cache validity (e.g., 24 hours)
      const cacheValidity = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const isCacheValid = Date.now() - timestamp < cacheValidity;

      if (isCacheValid) {
        const product = findProductInLocalStorage(data);
        if (product) {
          return product;
        }
      }
    }

    // If no valid cache or product not found in cache, fetch fresh data
    const freshData = await fetchPrintifyProducts();
    const product = findProductInLocalStorage(freshData);

    if (product) {
      return product;
    } else {
      throw new Error("Product not found");
    }
  } catch (error) {
    console.error("Error fetching product data:", error);
    throw error; // Rethrow the error to handle it further upstream if needed
  }
};

export const formatNumber = (num) => {
  return num?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
