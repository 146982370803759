import {
  FiChevronDown,
  FiShoppingBag,
  FiLogOut,
  FiShoppingCart,
  FiUser,
  FiLogIn,
  FiPhoneCall,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { useState } from "react";

import { useAuth } from "../adminComponents/AuthContext";
import { Link } from "react-router-dom";

const DropDown = () => {
  const [open, setOpen] = useState(false);
  const { currentUser, signOut } = useAuth();
  //   useEffect(() => {
  //     console.log("This is current user", currentUser);
  //   })

  return (
    <div className="flex items-center justify-center">
      <motion.div animate={open ? "open" : "closed"} className="relative">
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="flex items-center gap-2 text-white hover:text-purple-500 transition-colors"
        >
          <span className="font-medium text-sm">
            {currentUser
              ? `Hi, ${currentUser.email.split("@")[0]}`
              : "Hi Guest"}
          </span>
          <motion.span variants={iconVariants}>
            <FiChevronDown />
          </motion.span>
        </button>

        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: "-50%" }}
          className="flex flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[50%] w-30 overflow-hidden"
        >
          <Option setOpen={setOpen} Icon={FiShoppingBag} text="shop" />
          {/* <Option setOpen={setOpen} Icon={Fi} text="about" /> */}
          <Option setOpen={setOpen} Icon={FiUser} text="profile" />
          <Option setOpen={setOpen} Icon={FiPhoneCall} text="contact" />



          <CartOption setOpen={setOpen} Icon={FiShoppingCart} />
          {currentUser ? (
            <SignOutOption setOpen={setOpen} Icon={FiLogOut} />
          ) : (
            <SignInOption setOpen={setOpen} Icon={FiLogIn} />
          )}

          {/* <Option setOpen={setOpen} Icon={FiTrash} text="Remove" /> */}
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Option = ({ text, Icon, setOpen }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => setOpen(false)}
      className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer"
    >
      <motion.span variants={actionIconVariants}>
        <Icon />
      </motion.span>
      <span>
        <Link to={"/" + text}>
          {text.charAt(0).toUpperCase() + text.slice(1)}
        </Link>
      </span>
    </motion.li>
  );
};

const CartOption = ({ Icon, setOpen }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => setOpen(false)}
      className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer"
    >
      <motion.span variants={actionIconVariants}>
        <Icon />
      </motion.span>
      <span>
        <Link to="/shop/products/cart">Cart</Link>
      </span>
    </motion.li>
  );
};

const SignOutOption = ({ Icon, setOpen }) => {
  const { signOut } = useAuth();

  return (
    <motion.li
      variants={itemVariants}
      onClick={() => setOpen(false)}
      className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer"
    >
      <motion.span variants={actionIconVariants}>
        <Icon />
      </motion.span>
      <span>
        <Link to="/" onClick={signOut}>
          Sign Out
        </Link>
      </span>
    </motion.li>
  );
};

const SignInOption = ({ Icon, setOpen }) => {

  return (
    <motion.li
      variants={itemVariants}
      onClick={() => setOpen(false)}
      className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer"
    >
      <motion.span variants={actionIconVariants}>
        <Icon />
      </motion.span>
      <span>
        <Link to="/signin">
          Sign In
        </Link>
      </span>
    </motion.li>
  );
};
export default DropDown;

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
