import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const Address = () => {
  return (
    <section className="py-8 flex-grow rounded">
      <div className="container mx-auto flex justify-center">
        <div className="flex flex-col md:flex-row items-center gap-16">
          {/* Telephone Card */}
          {/* <div className="max-w-xs bg-gray-300 rounded shadow p-6 mb-4 md:mb-0 flex flex-col items-center">
            <div className="flex flex-col items-center mb-4">
              <FaPhone className="text-2xl text-dark-500 mb-2" />
              <h3 className="text-lg font-semibold text-gray-900">Telephone</h3>
            </div>
            <p className="text-gray-700">+1 (850) 345-2087</p>
          </div> */}

          {/* Email Card */}
          <div className="max-w-xs bg-gray-300 rounded shadow p-6 mb-4 md:mb-0 flex flex-col items-center">
            <div className="flex flex-col items-center mb-4">
              <FaEnvelope className="text-2xl text-dark-500 mb-2" />
              <h3 className="text-lg font-semibold text-gray-900">Email</h3>
            </div>
            <p className="text-gray-700">ogascountyng@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Address;
