import React from "react";

const DashboardSummary = () => {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Dashboard Summary</h2>
      <p>
        Welcome to the admin dashboard! This is a placeholder for your dashboard
        summary content.
      </p>
      {/* Add your dashboard summary content here */}
    </div>
  );
};

export default DashboardSummary;
