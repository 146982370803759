// AddPosts.js
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../../firebase"; // Import your Firestore instance

const addPost = async (postDetails) => {
  try {
    // Add a new post to the 'posts' collection
    const docRef = await setDoc(
      doc(firestore, "blog", postDetails.title.replace(/\s+/g, "-")),
      {
        title: postDetails.title,
        author: postDetails.author,
        publishDate: postDetails.publishDate,
        content: postDetails.content,
        category: postDetails.category,
        subtitle: postDetails.subtitle,
        views: 0
    }
    );
    console.log("Post added with ID: ", docRef);
  } catch (error) {
    console.error("Error adding post: ", error);
    throw new Error("Failed to add post");
  }
};

export default addPost;
