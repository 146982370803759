import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import app from "../../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import AdminLayout from "./AdminLayout";

const firestore = getFirestore(app);
const storage = getStorage(app);

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [editableProduct, setEditableProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(firestore, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsData = [];

        for (const docSnap of productsSnapshot.docs) {
          const productDoc = doc(firestore, "products", docSnap.id);
          const productData = await getDoc(productDoc);

          if (productData.exists()) {
            const productDetails = { id: docSnap.id, ...productData.data() };
            const imageName = `${productDetails.name.replace(/\s/g, "")}_${
              productDetails.name.length
            }`;
            const imageRef = ref(storage, `images/${imageName}.webp`);
            const imageUrl = await getDownloadURL(imageRef);
            productDetails.image = imageUrl;
            productsData.push(productDetails);
          }
        }

        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, [firestore, storage]);

  const handleEditClick = (productId) => {
    const productToEdit = products.find((product) => product.id === productId);
    setEditableProduct(productToEdit);
  };

  const handleCancelEdit = () => {
    setEditableProduct(null);
  };

  const handleSaveEdit = async () => {
    try {
      await updateDoc(
        doc(firestore, "products", editableProduct.id),
        editableProduct
      );
      setEditableProduct(null);
    } catch (error) {
      console.error("Error updating product: ", error);
    }
  };

  const handleDelete = async (productId) => {
    try {
      await deleteDoc(doc(firestore, "products", productId));
      setProducts(products.filter((product) => product.id !== productId));
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <AdminLayout>
      <div className="container mx-auto p-8">
        <h2 className="text-3xl font-bold mb-6">Product List</h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-gray-400 rounded-md mr-4"
          />
          <span>Total Products: {filteredProducts.length}</span>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Index</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Price</th>
                <th className="py-2 px-4 border-b">Amount</th>
                <th className="py-2 px-4 border-b">Color</th>
                <th className="py-2 px-4 border-b">Category</th>
                <th className="py-2 px-4 border-b">Type</th>
                <th className="py-2 px-4 border-b">Image</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map((product, index) => (
                <tr key={product.id}>
                  <td className="py-2 px-4 border-b">{indexOfFirstProduct + index + 1}</td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="text"
                        value={editableProduct.name}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            name: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      product.name
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="number"
                        value={editableProduct.price}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            price: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      `$${product.price}`
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="number"
                        value={editableProduct.amount}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            amount: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      product.amount
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="text"
                        value={editableProduct.color}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            color: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      product.color
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="text"
                        value={editableProduct.category}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            category: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      product.category
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {editableProduct && editableProduct.id === product.id ? (
                      <input
                        type="text"
                        value={editableProduct.type}
                        onChange={(e) =>
                          setEditableProduct({
                            ...editableProduct,
                            type: e.target.value,
                          })
                        }
                        className="block w-full border rounded-md p-2"
                      />
                    ) : (
                      product.type
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-48 h-48 object-cover"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  </td>
                  <td className="py-2 px-4 border-b flex flex-col space-y-2">
                    {editableProduct && editableProduct.id === product.id ? (
                      <>
                        <button
                          onClick={handleSaveEdit}
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md font-bold"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md font-bold"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleEditClick(product.id)}
                          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md font-bold"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(product.id)}
                          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md font-bold"
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-between">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
          >
            Previous
          </button>
          <span className="self-center">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
          >
            Next
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ProductList;
