import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../adminComponents/AuthContext";
import { firestore } from "../../firebase";
import { FiShoppingCart } from "react-icons/fi"; // Importing the cart icon from react-icons

const CartLink = () => {
  const { currentUser, signOut } = useAuth();
  const [cartItems, setCartItems] = useState([]);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      if (authUser) {
        // User is authenticated, fetch data from Firestore
        try {
          const userCartDoc = doc(firestore, "users", authUser.email);
          const userCartData = await getDoc(userCartDoc);

          if (userCartData.exists()) {
            const userCartItems = userCartData.data().cart || [];
            setCartItems(userCartItems);
          }
        } catch (error) {
          console.error("Error fetching user cart data: ", error);
        }
      } else {
        // User is not authenticated, retrieve cart data from localStorage
        const storedCartData =
          JSON.parse(localStorage.getItem("cartData")) || [];
        setCartItems(storedCartData);
      }
    };

    fetchCartData();
  }, [authUser]);

  const cartItemCount = cartItems.length; // Directly count the items in the cart

  return (
    <Link
      to="/shop/products/cart"
      className="relative text-dark hover:text-gray-700 transition duration-300"
    >
      <FiShoppingCart size={24} className="font-extrabold" /> {/* Use the cart icon from react-icons */}
      {cartItemCount > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full px-1">
          {cartItemCount}
        </span>
      )}
    </Link>
  );
};

export default CartLink;
