import React from "react";

const ProductOptions = ({
  options,
  selectedSize,
  selectedColor,
  handleSizeChange,
  handleColorChange,
}) => (
  <>
    {options.some((opt) => opt.name.toLowerCase().includes("sizes")) && (
      <div className="mb-4">
        <p className="font-bold">Sizes:</p>
        <select
          value={selectedSize}
          onChange={(e) => handleSizeChange(e.target.value)}
          className="w-full p-2 mt-1 border rounded-md"
        >
          {options
            .find((opt) => opt.name.toLowerCase().includes("sizes"))
            .values.map((size) => (
              <option key={size.id} value={size.title}>
                {size.title}
              </option>
            ))}
        </select>
      </div>
    )}
    {options.some((opt) => opt.name.toLowerCase().includes("color")) && (
      <div className="mb-4">
        <p className="font-bold">Colors:</p>
        <select
          value={selectedColor}
          onChange={(e) => handleColorChange(e.target.value)}
          className="w-full p-2 mt-1 border rounded-md"
        >
          {options
            .find((opt) => opt.name.toLowerCase().includes("color"))
            .values.map((color) => (
              <option key={color.id} value={color.title}>
                {color.title}
              </option>
            ))}
        </select>
      </div>
    )}
  </>
);

export default ProductOptions;
